<template>
  <button
    class="clothing-item-tile"
    :class="{active: isSelectedItem, preselected: itemObject.isPreselected, preselectionNotMandatory: preselectionMandatory}"
    @click="clothingItemTileClickHandler(itemObject)"
  >
    <img
      class="clothing-item-tile__image"
      :src="itemObject.thumbnail" alt="" />

    <span
      class="clothing-item-tile__item-button"></span>

    <div class="clothing-item-tile__description">
      <p v-if="itemObject.title" class="clothing-item-tile__title">{{itemObject.title}}</p>
      <p v-if="itemObject.color">{{itemObject.color}}</p>
    </div>
  </button>
</template>

<script>
import { categoriesWithNonMandatoryPreselection } from '../service/CategoryDataService'

export default {
  name: 'TheClothingItemTile',
  computed: {
    preselectionMandatory () {
      return categoriesWithNonMandatoryPreselection.includes(parseInt(this.$store.state.categoryId))
    },
    isSelectedItem () {
      for (const item of this.$store.state.selectedClothingItems[this.$store.state.categoryId]) {
        if (item.id === this.itemObject.id) {
          return true
        }
      }

      return false
    }
  },
  methods: {
    clothingItemTileClickHandler (itemObject) {
      // return on click of selected preselected item
      if (this.itemObject.isPreselected && this.isSelectedItem && !this.preselectionMandatory) {
        return
      }

      // if the selected item is an item inside a preselection category
      // but not the preselected item itself,
      // delete the item and readd the preselected one
      if (
        this.isSelectedItem === true &&
        this.$store.state.categories[this.itemObject.category - 1].hasPreselection === true &&
        this.itemObject.isPreselected === false &&
        !this.preselectionMandatory
      ) {
        this.$store.commit('removeClothingItem', itemObject)

        for (const item of this.$store.state.availableClothingItems[this.itemObject.category]) {
          if (item.isPreselected) {
            this.$store.commit('addClothingItem', item)
            return
          }
        }
      }

      // If the Item is not a multiselect item, not currently selected
      // and the category already has a selection
      // remove all articles in category and add this item
      if (
        this.itemObject.isMultiSelect === false &&
        this.isSelectedItem === false &&
        this.$store.state.selectedClothingItems[itemObject.category].length >= 1
      ) {
        this.$store.commit('resetSelectedItemsInCategory', itemObject.category)
        this.$store.commit('addClothingItem', itemObject)
        return
      }

      if (
        this.itemObject.isMultiSelect === true &&
        this.isSelectedItem === true
      ) {
        this.$store.commit('removeClothingItem', itemObject)
        return
      }

      if (this.isSelectedItem) {
        this.$store.commit('removeClothingItem', itemObject)
        return
      }

      this.$store.commit('addClothingItem', itemObject)
    }
  },
  props: {
    itemObject: Object
  }
}
</script>

<style scoped lang="scss">
@use "../scss/animation";
@use "../scss/colors";
@use "../scss/mixins";

.clothing-item-tile {
  background-color: transparent;
  border: none;
  color: colors.$midnight;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  position: relative;
  transition: background-color animation.$basic-transition-timing, box-shadow animation.$basic-transition-timing;
  border-radius: mixins.vw(10px, 1024);
  height: mixins.vw(220px, 1024);
  padding: mixins.vw(0 10px, 1024);
  max-width: mixins.vw(110px, 1024);

  @include mixins.mq("desktop") {
    border-radius: mixins.vw(10px, 1440);
    height: mixins.vw(270px, 1440);
    padding: mixins.vw(0 10px, 1440);
    max-width: mixins.vw(130px, 1440);
  }

  @include mixins.mq("full-hd") {
    border-radius: mixins.vw(10px, 1920);
    height: mixins.vw(270px, 1920);
    padding: mixins.vw(0 10px, 1920);
    max-width: mixins.vw(130px, 1920);
  }

  &:not(.active) {
    .clothing-item-tile {
      &__item-button {
        &::after {
          transform: translate(-50%, -50%) rotate(90deg);
        }

        &::before {
          transform: translate(-50%, -50%);
        }
      }
    }

    &:hover {
      background-color: white;
      box-shadow: mixins.vw(0 5px 25px, 1024) rgba(0, 0, 0, 0.15);

      @include mixins.mq("desktop") {
        box-shadow: mixins.vw(0 5px 25px, 1440) rgba(0, 0, 0, 0.15);
      }

      @include mixins.mq("full-hd") {
        box-shadow: mixins.vw(0 5px 25px, 1920) rgba(0, 0, 0, 0.15);
      }

      .clothing-item-tile {
        &__item-button {
          opacity: 1 !important;
        }
      }
    }
  }

  &.active {
    background-color: white;
    box-shadow: mixins.vw(0 0 0 3px, 1024) colors.$candlelight;

    @include mixins.mq("desktop") {
      box-shadow: mixins.vw(0 0 0 3px, 1440) colors.$candlelight;
    }

    @include mixins.mq("full-hd") {
      box-shadow: mixins.vw(0 0 0 3px, 1920) colors.$candlelight;
    }

    &:hover {
      .clothing-item-tile {
        &__item-button {
          background-color: colors.$midnight;

          &::after,
          &::before {
            background-color: white;
          }
        }
      }
    }

    .clothing-item-tile {
      &__item-button {
        opacity: 1;
      }
    }
  }

  &.preselected {
    &:not(.preselectionNotMandatory) {
      &.active {
        .clothing-item-tile {
          &__item-button {
            display: none;
          }
        }
      }
    }
  }

  &__button {
    box-shadow: none;
    cursor: pointer;
    position: relative;
    transition: box-shadow animation.$basic-transition-timing;
    width: 100%;
    margin-bottom: mixins.vw(8px, 1024);

    @include mixins.mq("desktop") {
      margin-bottom: mixins.vw(8px, 1440);
    }

    @include mixins.mq("full-hd") {
      margin-bottom: mixins.vw(8px, 1920);
    }
  }

  &__item-button {
    background-color: white;
    border: none;
    cursor: pointer;
    font-size: 0;
    left: 50%;
    opacity: 0;
    position: absolute;
    transform: translateX(-50%);
    transition: background-color animation.$basic-transition-timing, opacity animation.$basic-transition-timing;
    height: mixins.vw(32px, 1024);
    width: mixins.vw(32px, 1024);
    top: mixins.vw(80px, 1024);
    border-radius: mixins.vw(100px, 1024);

    @include mixins.mq("desktop") {
      height: mixins.vw(32px, 1440);
      width: mixins.vw(32px, 1440);
      top: mixins.vw(80px, 1440);
      border-radius: mixins.vw(100px, 1440);
    }

    @include mixins.mq("full-hd") {
      height: mixins.vw(32px, 1920);
      width: mixins.vw(32px, 1920);
      top: mixins.vw(80px, 1920);
      border-radius: mixins.vw(100px, 1920);
    }

    &::after,
    &::before {
      background-color: colors.$midnight;
      content: "";
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: background-color animation.$basic-transition-timing;
      height: mixins.vw(1px, 1024);
      width: mixins.vw(12px, 1024);

      @include mixins.mq("desktop") {
        height: mixins.vw(1px, 1440);
        width: mixins.vw(12px, 1440);
      }

      @include mixins.mq("full-hd") {
        height: mixins.vw(1px, 1920);
        width: mixins.vw(12px, 1920);
      }
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &__description {
    text-align: center;
    width: 100%;
    font-size: mixins.vw(11px, 1024);
    line-height: 1.3;

    @include mixins.mq("desktop") {
      font-size: mixins.vw(13px, 1440);
    }

    @include mixins.mq("full-hd") {
      font-size: mixins.vw(13px, 1920);
    }
  }

  &__image {
    display: block;
    object-fit: contain;
    height: mixins.vw(96px, 1024);
    width: mixins.vw(79px, 1024);
    margin: mixins.vw(15px, 1024) auto;

    @include mixins.mq("desktop") {
      height: mixins.vw(136px, 1440);
      width: mixins.vw(109px, 1440);
      margin: mixins.vw(15px, 1440) auto;
    }

    @include mixins.mq("full-hd") {
      height: mixins.vw(136px, 1920);
      width: mixins.vw(109px, 1920);
      margin: mixins.vw(15px, 1920) auto;
    }
  }

  &__title {
    font-weight: 600;
  }
}
</style>
