import CategoryDataService from './CategoryDataService'

export default class {
  getProductsForSubdepartmentAndGender (store, subdepartmentId, genderId) {
    const allProducts = require('../data/product.json')
    const filteredProducts = {}
    const allCategories = new CategoryDataService().getCategories()

    function pushValidItem (item) {
      if (!(item.category in filteredProducts)) {
        filteredProducts[item.category] = []
      }

      filteredProducts[item.category].push(item)
    }

    for (const category of store.state.categories) {
      category.hasPreselection = false
    }

    for (const item of allProducts) {
      if (item.thumbnail === null || item.thumbnail.indexOf('icon') >= 0) {
        continue
      }

      // Push product into filteredProducts if relations match department or genderId
      if (typeof item.relations !== 'string') {
        // Check if there is an item relation for current gender and department
        const itemIsValid = item.relations.find(relation => {
          let returnBool = true

          if (relation.departmentId) {
            if (parseInt(relation.departmentId) !== parseInt(store.state.departmentId)) {
              returnBool = false
            }
          }

          if (relation.subdepartmentId) {
            if (relation.subdepartmentId !== subdepartmentId) {
              returnBool = false
            }
          }

          if (relation.genderId) {
            if (relation.genderId !== genderId) {
              returnBool = false
            }
          }

          return returnBool
        })

        if (itemIsValid) {
          item.isMultiSelect = !!allCategories[item.category - 1].multiselect

          if (itemIsValid.isPreselected) {
            item.isPreselected = true
            store.commit('addClothingItem', item)
            store.state.categories[item.category - 1].hasPreselection = true
          } else {
            item.isPreselected = false
          }

          pushValidItem(item)
        }
      } else if (item.relations === 'all') {
        item.isMultiSelect = allCategories[item.category - 1].multiselect
        pushValidItem(item)
      } else if (item.relations === 'addDepartments') {
        for (const departmentId of item.relationDepartments) {
          if (departmentId === parseInt(store.state.departmentId)) {
            item.isMultiSelect = allCategories[item.category - 1].multiselect
            pushValidItem(item)
          }
        }
      } else if (item.relations === 'preSelectedDepartments') {
        for (const departmentId of item.relationDepartments) {
          if (departmentId === parseInt(store.state.departmentId)) {
            item.isPreselected = true
            store.commit('addClothingItem', item)
            store.state.categories[item.category - 1].hasPreselection = true
          }
        }
      } else {
        item.isMultiSelect = allCategories[item.category - 1].multiselect

        if (item.relations === 'allPreselect') {
          item.isPreselected = true
          store.commit('addClothingItem', item)
          store.state.categories[item.category - 1].hasPreselection = true
        } else {
          item.isPreselected = false
        }

        pushValidItem(item)
      }
    }

    return filteredProducts
  }
}
