<template>
  <div class="configuration-reset-notice">
    <div class="notice-wrapper" :class="{show: isResetNoticeOpen}">
      <div class="dialogue">
        <p class="dialogue__headline">ACHTUNG</p>

        <p>
          Mit dieser Änderung setzen Sie <br> ihre aktuelle Auswahl zurück.
        </p>

        <p>
          Möchten Sie fortfahren und Ihre Auswahl zurücksetzen?
        </p>

        <div class="dialogue__button-wrapper">
          <button class="button button--reset" @click="resetButtonClickHandler" type="button">Ja, bitte Auswahl löschen</button>
          <button class="button button--cancel" @click="cancelButtonClickHandler" type="button">Nein</button>
        </div>
      </div>
    </div>

    <div class="reset-layer" :class="{open: isResetting}">
      <img class="logo" src="/img/koettter-logo.svg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isResetNoticeOpen () {
      return this.$store.state.isResetNoticeOpen
    }
  },
  data: function () {
    return {
      isResetting: false
    }
  },
  methods: {
    cancelButtonClickHandler () {
      this.$store.commit('setResetNoticeOpen', false)
    },
    resetButtonClickHandler () {
      this.isResetting = true

      window.setTimeout(() => {
        this.$store.commit('resetConfiguration')
        this.$store.commit('setResetNoticeOpen', false)
        window.setTimeout(() => {
          this.isResetting = false
        }, 1600)
      }, 500)
    }
  },
  name: 'TheConfigurationResetNotice'
}
</script>

<style lang="scss">
@use "../scss/animation";
@use "../scss/colors";
@use "../scss/mixins";

.configuration-reset-notice {
  .notice-wrapper {
    height: 100vh;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: background-color animation.$basic-transition-timing;
    width: 100vw;
    z-index: 5;

    &.show {
      background-color: rgba(colors.$midnight, 0.5);
      pointer-events: auto;

      .dialogue {
        opacity: 1;
        pointer-events: auto;
      }
    }

    .dialogue {
      background-color: white;
      color: colors.$midnight;
      left: 50%;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: opacity animation.$basic-transition-timing;
      box-shadow: mixins.vw(0 5px 15px, 1024) rgba(0, 0, 0, 0.055);
      padding: mixins.vw(76px 81px 90px, 1024);
      min-width: 70vw;

      @include mixins.mq("desktop") {
        box-shadow: mixins.vw(0 5px 15px, 1440) rgba(0, 0, 0, 0.055);
        padding: mixins.vw(76px 81px 90px, 1440);
        min-width: unset;
      }

      @include mixins.mq("full-hd") {
        box-shadow: mixins.vw(0 5px 15px, 1920) rgba(0, 0, 0, 0.055);
        padding: mixins.vw(76px 81px 90px, 1920);
      }

      &__headline {
        display: inline-block;
        line-height: 140%;
        position: relative;
        font-size: mixins.vw(21px, 1024);
        margin-bottom: mixins.vw(16px, 1024);

        @include mixins.mq("desktop") {
          font-size: mixins.vw(21px, 1440);
          margin-bottom: mixins.vw(16px, 1440);
        }

        @include mixins.mq("full-hd") {
          font-size: mixins.vw(21px, 1920);
          margin-bottom: mixins.vw(16px, 1920);
        }

        &::after {
          background-image: url("/img/icon-warning.svg");
          background-size: 100% 100%;
          content: "";
          position: absolute;
          top: 40%;
          transform: translateY(-50%);
          width: mixins.vw(24px, 1024);
          right: mixins.vw(-39px, 1024);
          height: mixins.vw(27px, 1024);

          @include mixins.mq("desktop") {
            width: mixins.vw(24px, 1440);
            right: mixins.vw(-39px, 1440);
            height: mixins.vw(27px, 1440);
          }

          @include mixins.mq("full-hd") {
            width: mixins.vw(24px, 1920);
            right: mixins.vw(-39px, 1920);
            height: mixins.vw(27px, 1920);
          }
        }

        &,
        + p {
          font-weight: 600;
        }

        + p {
          line-height: 140%;
          font-weight: 600;
          font-size: mixins.vw(32px, 1024);
          margin-bottom: mixins.vw(32px, 1024);

          @include mixins.mq("desktop") {
            font-size: mixins.vw(32px, 1440);
            margin-bottom: mixins.vw(32px, 1440);
          }

          @include mixins.mq("full-hd") {
            font-size: mixins.vw(32px, 1920);
            margin-bottom: mixins.vw(32px, 1920);
          }
        }
      }

      &__button-wrapper {
        display: flex;
        justify-content: center;
        margin-top: mixins.vw(24px, 1024);
        gap: mixins.vw(15px, 1024);

        @include mixins.mq("desktop") {
          margin-top: mixins.vw(24px, 1440);
          gap: mixins.vw(15px, 1440);
        }

        @include mixins.mq("full-hd") {
          margin-top: mixins.vw(24px, 1920);
          gap: mixins.vw(15px, 1920);
        }

        .button {
          border: none;
          box-shadow: none;
          cursor: pointer;
          line-height: 111.5%;
          outline: none;
          transition: background-color animation.$basic-transition-timing, color animation.$basic-transition-timing;
          font-size: mixins.vw(14px, 1024);
          padding: mixins.vw(14px, 1024);
          min-width: mixins.vw(162px, 1024);

          @include mixins.mq("desktop") {
            font-size: mixins.vw(14px, 1440);
            padding: mixins.vw(14px, 1440);
            min-width: mixins.vw(162px, 1440);
          }

          @include mixins.mq("full-hd") {
            font-size: mixins.vw(14px, 1920);
            padding: mixins.vw(14px, 1920);
            min-width: mixins.vw(162px, 1920);
          }

          &--cancel {
            background-color: colors.$midnight;
            color: white;

            &:hover {
              background-color: colors.$candlelight;
              color: colors.$midnight;
            }
          }

          &--reset {
            background-color: white;
            color: colors.$midnight;
            border: mixins.vw(1px, 1024) solid colors.$midnight;

            @include mixins.mq("desktop") {
              border: mixins.vw(1px, 1440) solid colors.$midnight;
            }

            @include mixins.mq("full-hd") {
              border: mixins.vw(1px, 1920) solid colors.$midnight;
            }

            &:hover {
              background-color: colors.$wild-sand;
            }
          }
        }
      }
    }
  }

  .reset-layer {
    background-color: colors.$midnight;
    height: 100vh;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    transform: translateY(-100vh);
    width: 100vw;
    z-index: 6;

    &.open {
      animation: reset-layer-open 2s forwards;

      .logo {
        animation: reset-layer-open-logo 2s forwards;
      }
    }

    .logo {
      height: auto;
      left: 50vw;
      position: fixed;
      top: 50vh;
      transform: translateX(-50%) translateY(-50%);
      width: 20%;
    }
  }
}

@keyframes reset-layer-open {
  0% {
    transform: translateY(-100vh);
  }

  25% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100vh);
  }
}

@keyframes reset-layer-open-logo {
  0% {
    transform: translateX(-50%) translateY(calc(100vh));
  }

  25% {
    transform: translateX(-50%) translateY(0);
  }

  75% {
    transform: translateX(-50%) translateY(0);
  }

  100% {
    transform: translateX(-50%) translateY(calc(-100vh));
  }
}
</style>
