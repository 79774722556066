<template>
  <div class="clothing-catergory-selection" :class="{show: genderId}">
    <HeadlineWithArrow class="headline" :text="headlineText" />

    <div class="clothing-catergory-selection__wrapper">
        <TheClothingCategoryTile
          v-for="category in clothingCategories"
          :image="getCategoryImage(category.image)"
          :title="category.title"
          :category-id="category.id"
          :key="category.value"
        />
    </div>
  </div>
</template>

<script>
import HeadlineWithArrow from './HeadlineWithArrow'
import TheClothingCategoryTile from './TheClothingCategoryTile'

export default {
  computed: {
    genderId () {
      return this.$store.state.genderId
    },
    clothingCategories () {
      return this.$store.state.categories
    }
  },
  components: {
    TheClothingCategoryTile,
    HeadlineWithArrow
  },
  data: function () {
    return {
      headlineText: 'Kategorie wählen'
    }
  },
  methods: {
    // Get categoryimage for current gender or default to male icon if unset
    getCategoryImage (categoryImage) {
      return (categoryImage[this.genderId]) ? categoryImage[this.genderId] : categoryImage[1]
    }
  },
  name: 'TheClothingCategorySelection'
}
</script>

<style scoped lang="scss">
@use '../scss/animation';
@use '../scss/mixins';

.clothing-catergory-selection {
  opacity: 0;
  transition: opacity animation.$long-transition-timing;
  padding: mixins.vw(14px 25px 28px 25px, 1024);
  min-height: mixins.vw(245px, 1024);
  background-color: white;

  @include mixins.mq("desktop") {
    padding: mixins.vw(22px 47px 28px 56px, 1440);
    min-height: mixins.vw(245px, 1440);
  }

  @include mixins.mq("full-hd") {
    padding: mixins.vw(22px 47px 28px 56px, 1920);
    min-height: mixins.vw(245px, 1920);
  }

  &.show {
    opacity: 1;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: mixins.vw(24px, 1024);
    max-width: mixins.vw(878px, 1024);

    @include mixins.mq("desktop") {
      gap: mixins.vw(24px, 1440);
      max-width: mixins.vw(878px, 1440);
    }

    @include mixins.mq("full-hd") {
      gap: mixins.vw(24px, 1920);
      max-width: mixins.vw(878px, 1920);
    }
  }
}
</style>
