export default class {
  getSubdepartmentsByDepartmentId (departmentId) {
    const products = require('../data/product.json')
    const subdepartments = require('../data/subdepartment.json')
    let relations = []

    products.forEach(product => {
      if (typeof product.relations !== 'string') {
        const relation = product.relations.filter(relation => relation.departmentId === departmentId)
        if (relation.length > 0) {
          const result = relation.map(aRelation => aRelation.subdepartmentId)
          relations = relations.concat(result)
        }
      }
    })

    relations = relations.filter((v, i, a) => a.indexOf(v) === i)
    return subdepartments.filter(subdepartment => relations.includes(subdepartment.id))
  }
}
