<template>
  <div
    :class="{hidden: alreadyInteracted}"
    class="start-screen">
    <img class="start-screen__logo" src="/img/koetter-logo-blue.svg" alt="">

    <img class="start-screen__visual start-screen__visual--left" src="/img/startscreen-visual-left.png" alt="">
    <img class="start-screen__visual start-screen__visual--right" src="/img/startscreen-visual-right.png" alt="">

    <div class="dialogue">
      <h1>Dienstkleidung konfigurieren.</h1>
      <p>
        Ganz easy – mit dem KÖTTER Services Virtual-Dressing-Room.
      </p>

      <div class="departments">
        <button
          class="button button"
          v-for="department in departments"
          :key="department.id"
          @click="pushInitialConfig(department.id, department.title)"
        >
          {{department.title}}
        </button>
      </div>

      <span
        class="hint"
        :class="{hide: isGenderSet}">
        Bereich auswählen
      </span>
    </div>
  </div>
</template>

<script>

import CategoryDataService from '../service/CategoryDataService'

export default {
  data: function () {
    const categoryDataService = new CategoryDataService()

    return {
      departments: categoryDataService.getDepartments(),
      alreadyInteracted: false
    }
  },
  computed: {
    isGenderSet: function () {
      return this.$store.state.genderId
    }
  },
  methods: {
    pushInitialConfig (departmentId, departmentTitle) {
      this.$store.commit('setDepartment', { departmentId, departmentTitle })
      this.alreadyInteracted = true
      this.$store.commit('setSubdepartments', departmentId)
      const firstSubdepartmentFromStore = this.$store.state.subdepartments[0]
      this.$store.commit('setSubdepartment', { subdepartmentId: firstSubdepartmentFromStore.id, subdepartmentTitle: firstSubdepartmentFromStore.title })
      this.$store.commit('setGenderAndAvailableProducts', { genderId: '1', genderTitle: 'Herren' })
    }
  },
  name: 'TheStartScreen'
}
</script>

<style scoped lang="scss">
@use '../scss/animation';
@use '../scss/colors';
@use '../scss/mixins';
@use '../scss/basics';

.start-screen {
  background: linear-gradient(270deg, rgba(233,232,228,1) 0%, rgba(214,215,209,1) 100%);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: 7;

  &.hidden {
    animation: scale-and-move-left 1s forwards;
    pointer-events: none;
  }

  &__logo {
    position: absolute;
    left: mixins.vw(20px, 1024);
    top: mixins.vw(20px, 1024);

    @include mixins.mq("desktop") {
      left: mixins.vw(20px, 1440);
      top: mixins.vw(20px, 1440);
    }

    @include mixins.mq("full-hd") {
      left: mixins.vw(20px, 1920);
      top: mixins.vw(20px, 1920);
    }
  }

  &__visual {
    pointer-events: none;
    bottom: 0;
    position: absolute;

    &--left {
      left: -10px;
      height: mixins.vw(393px, 1024);
      width: mixins.vw(336px, 1024);

      @include mixins.mq("desktop") {
        height: mixins.vw(493px, 1440);
        width: mixins.vw(436px, 1440);
      }

      @include mixins.mq("full-hd") {
        height: mixins.vw(493px, 1920);
        width: mixins.vw(436px, 1920);
      }
    }

    &--right {
      right: -10px;
      height: mixins.vw(393px, 1024);
      width: mixins.vw(336px, 1024);

      @include mixins.mq("desktop") {
        height: mixins.vw(493px, 1440);
        width: mixins.vw(436px, 1440);
      }

      @include mixins.mq("full-hd") {
        height: mixins.vw(493px, 1920);
        width: mixins.vw(436px, 1920);
      }
    }
  }

  .dialogue {
    display: block;
    margin: mixins.vw(119px, 1024) auto 0 auto;
    max-width: mixins.vw(500px, 1024);

    @include mixins.mq("desktop") {
      margin: mixins.vw(189px, 1440) auto 0 auto;
      max-width: mixins.vw(610px, 1400);
    }

    @include mixins.mq("full-hd") {
      margin: mixins.vw(189px, 1920) auto 0 auto;
      max-width: mixins.vw(610px, 1920);
    }

    > h1,
    > p {
      color: colors.$midnight;
      font-size: mixins.vw(30px, 1024);

      @include mixins.mq("desktop") {
        font-size: mixins.vw(32px, 1440);
      }

      @include mixins.mq("full-hd") {
        font-size: mixins.vw(42px, 1920);
      }
      line-height: 111.5%;
    }

    > h1 {
      font-weight: 400;
      margin-bottom: 16px;
    }

    > p {
      font-weight: 600;
      line-height: 1.2;
      margin-bottom: mixins.vw(35px, 1024);

      @include mixins.mq("desktop") {
        margin-bottom: mixins.vw(65px, 1440);
      }

      @include mixins.mq("full-hd") {
        margin-bottom: mixins.vw(65px, 1920);
      }
    }

    .departments {
      display: flex;
      gap: mixins.vw(24px, 1024);

      @include mixins.mq("desktop") {
        gap: mixins.vw(24px, 1440);
      }

      @include mixins.mq("full-hd") {
        gap: mixins.vw(24px, 1920);
      }
    }

    .button {
      border: none;
      box-shadow: none;
      cursor: pointer;
      line-height: 111.5%;
      outline: none;
      transition: background-color animation.$basic-transition-timing, color animation.$basic-transition-timing;
      background-color: colors.$midnight;
      color: white;
      font-size: mixins.vw(10px, 1024);
      min-width: mixins.vw(160px, 1024);
      padding: mixins.vw(14px, 1024);

      @include mixins.mq("desktop") {
        font-size: mixins.vw(14px, 1440);
        min-width: mixins.vw(192px, 1440);
        padding: mixins.vw(14px, 1440);
      }

      @include mixins.mq("full-hd") {
        font-size: mixins.vw(14px, 1920);
        min-width: mixins.vw(200px, 1920);
        padding: mixins.vw(14px, 1920);
      }

      &:hover {
        background-color: colors.$candlelight;
        color: colors.$midnight;
      }
    }
  }

  .hint {
    color: colors.$midnight;
    position: relative;
    display: inline-block;
    font-weight: 600;
    line-height: 115%;
    font-size: mixins.vw(14px, 1024);
    margin-top: mixins.vw(41px, 1024);
    padding-left: mixins.vw(34px, 1024);
    opacity: 0;
    transform: translateY(10px);
    animation: fade-in-from-bottom 0.25s forwards;
    animation-delay: 0.5s;

    @include mixins.mq("desktop") {
      font-size: mixins.vw(14px, 1440);
      margin-top: mixins.vw(41px, 1440);
      padding-left: mixins.vw(34px, 1440);
    }

    @include mixins.mq("full-hd") {
      font-size: mixins.vw(14px, 1920);
      margin-top: mixins.vw(41px, 1920);
      padding-left: mixins.vw(34px, 1920);
    }

    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      background-image: url("/img/icon-arrow-curve-up.svg");
      background-size: 100% 100%;
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: mixins.vw(20px, 1024);
      right: mixins.vw(-20px, 1024);
      width: mixins.vw(18px, 1024);

      @include mixins.mq("desktop") {
        height: mixins.vw(20px, 1440);
        right: mixins.vw(-20px, 1440);
        width: mixins.vw(18px, 1440);
      }

      @include mixins.mq("full-hd") {
        height: mixins.vw(20px, 1920);
        right: mixins.vw(-20px, 1920);
        width: mixins.vw(18px, 1920);
      }
    }

    &::before {
      color: white;
      content: '1';
      background-color: colors.$midnight;
      left: 0;
      font-size: mixins.vw(14px, 1024);
      padding: mixins.vw(5px, 1024) mixins.vw(8px, 1024);
      border-radius: mixins.vw(15px, 1024);

      @include mixins.mq("desktop") {
        font-size: mixins.vw(14px, 1440);
        padding: mixins.vw(5px, 1440) mixins.vw(8px, 1440);
        border-radius: mixins.vw(15px, 1440);
      }

      @include mixins.mq("full-hd") {
        font-size: mixins.vw(14px, 1920);
        padding: mixins.vw(5px, 1920) mixins.vw(8px, 1920);
        border-radius: mixins.vw(15px, 1920);
      }
    }
  }
}

@keyframes scale-and-move-left {
  0% {
    transform: scale(1);
  }

  40% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(0.9) translateX(-120vw);
  }
}

@keyframes fade-in-from-bottom {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
