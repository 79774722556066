import { createStore } from 'vuex'
import ProductDataService from '../service/ProductDataService'
import CategoryDataService from '../service/CategoryDataService'
import SubdepartmentDataService from '../service/SubdepartmentDataService'

const productDataService = new ProductDataService()
const categoryDataService = new CategoryDataService()
const subdepartmentDataService = new SubdepartmentDataService()

const defaultState = () => {
  return {
    addedProduct: null,
    deletedProduct: null,
    availableClothingItems: [],
    subdepartments: [],
    subdepartmentId: null,
    subdepartmentTitle: '- Bereich wählen -',
    categories: [],
    itemAmount: 0,
    categoryId: null,
    departmentId: null,
    departmentTitle: null,
    genderId: null,
    genderTitle: null,
    isOverviewOpen: false,
    isResetNoticeOpen: false,
    resetCache: {},
    selectedClothingItems: {
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
      7: [],
      8: [],
      9: [],
      10: [],
      11: []
    },
    sliderPosition: 0,
    sliderEndPosition: 0,
    isGeneratingPdf: false
  }
}

export default createStore({
  state: defaultState,
  mutations: {
    setSliderPosition (state, number) {
      state.sliderPosition = number
    },
    setSliderEndPosition (state, number) {
      state.sliderEndPosition = number
    },
    setOverviewOpen (state, targetstate) {
      state.isOverviewOpen = targetstate
    },
    setResetNoticeOpen (state, targetstate) {
      state.isResetNoticeOpen = targetstate
    },
    addClothingItem (state, itemObject) {
      state.selectedClothingItems[itemObject.category].push(itemObject)
      state.itemAmount++
      state.addedProduct = itemObject

      window.setTimeout(() => {
        state.addedProduct = null
      }, 2500)
    },
    setGenderAndAvailableProducts (state, { genderId, genderTitle }) {
      state.genderId = genderId
      state.genderTitle = genderTitle

      state.categories = categoryDataService.getCategories()
      state.availableClothingItems = productDataService.getProductsForSubdepartmentAndGender(this, state.subdepartmentId, state.genderId)
    },
    setDepartment (state, { departmentId, departmentTitle }) {
      state.departmentId = departmentId
      state.departmentTitle = departmentTitle
    },
    setSubdepartments (state, departmentId) {
      state.subdepartments = subdepartmentDataService.getSubdepartmentsByDepartmentId(departmentId)
    },
    setSubdepartment (state, { subdepartmentId, subdepartmentTitle }) {
      state.subdepartmentId = subdepartmentId
      state.subdepartmentTitle = subdepartmentTitle
    },
    updateCategory (state, categoryId) {
      state.categoryId = categoryId
    },
    removeClothingItem (state, itemObject) {
      const storeClosingItemIndex = state.selectedClothingItems[itemObject.category].findIndex((item) => item.id === itemObject.id)
      state.selectedClothingItems[itemObject.category].splice(storeClosingItemIndex, 1)
      state.itemAmount--
      state.deletedProduct = itemObject

      window.setTimeout(() => {
        state.deletedProduct = null
      }, 2500)
    },
    resetSelectedItemsInCategory (state, categoryId) {
      state.selectedClothingItems[categoryId] = []
      state.itemAmount--
    },
    writeResetCache (state, {
      departmentId,
      departmentTitle,
      genderId,
      genderTitle,
      subdepartmentId,
      subdepartmentTitle
    }) {
      if (departmentId) {
        state.resetCache.departmentId = departmentId
        state.resetCache.departmentTitle = departmentTitle
      }

      if (genderId) {
        state.resetCache.genderId = genderId
        state.resetCache.genderTitle = genderTitle
      }

      if (subdepartmentId) {
        state.resetCache.subdepartmentId = subdepartmentId
        state.resetCache.subdepartmentTitle = subdepartmentTitle
      }
    },
    resetConfiguration (state) {
      const resetCache = state.resetCache
      Object.assign(state, defaultState())

      if (resetCache.departmentId) {
        state.departmentId = resetCache.departmentId
        state.departmentTitle = resetCache.departmentTitle
        state.subdepartments = subdepartmentDataService.getSubdepartmentsByDepartmentId(resetCache.departmentId)
      }

      if (resetCache.subdepartmentId) {
        state.subdepartmentId = resetCache.subdepartmentId
        state.subdepartmentTitle = resetCache.subdepartmentTitle
      }

      if (resetCache.genderId) {
        state.genderId = resetCache.genderId
        state.genderTitle = resetCache.genderTitle

        state.categories = categoryDataService.getCategories()
        state.availableClothingItems = productDataService.getProductsForSubdepartmentAndGender(this, state.subdepartmentId, state.genderId)
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
