<template>
  <div class="department-selection">
    <button
      @click="updateDepartment(department.id, department.title)"
      class="department-selection__button"
      :class="{active: checkActiveState(department.id)}"
      v-for="department in departments"
      :key="department.id"
      :department-id="department.id">
      {{ department.title }}
    </button>
  </div>
</template>

<script>
import CategoryDataService from '../service/CategoryDataService'

export default {
  computed: {
    isGenderSelected () {
      return this.$store.state.genderId
    }
  },
  data: function () {
    const categoryDataService = new CategoryDataService()

    return {
      departments: categoryDataService.getDepartments()
    }
  },
  methods: {
    updateDepartment (departmentId, departmentTitle) {
      if (this.isGenderSelected && this.checkActiveState(departmentId) === false) {
        this.$store.commit('setResetNoticeOpen', true)
        this.$store.commit(
          'writeResetCache',
          {
            departmentId: departmentId,
            departmentTitle: departmentTitle,
            genderId: false,
            genderTitle: false,
            subdepartmentId: false,
            subdepartmentTitle: false
          }
        )
      } else {
        this.$store.commit('setDepartment', { departmentId, departmentTitle })
        this.$store.commit('setSubdepartments', departmentId)
        this.$store.commit('setSubdepartment', { subdepartmentId: null, subdepartmentTitle: '- Bitte wählen -' })
      }
    },
    checkActiveState (departmentId) {
      return departmentId === this.$store.state.departmentId
    }
  },
  name: 'TheDepartmentSelection'
}
</script>

<style scoped lang="scss">
@use '../scss/animation';
@use '../scss/colors';
@use '../scss/mixins';

.department-selection {
  display: flex;
  width: 100%;

  &__button {
    background-color: transparent;
    border: none;
    color: colors.$midnight;
    cursor: pointer;
    line-height: 111.5%;
    opacity: 1;
    position: relative;
    font-size: mixins.vw(12px, 1024);

    @include mixins.mq("desktop") {
      font-size: mixins.vw(16px, 1440);
    }

    @include mixins.mq("full-hd") {
      font-size: mixins.vw(16px, 1920);
    }

    &:not(:last-child) {
      margin-right: mixins.vw(25px, 1024);

      @include mixins.mq("desktop") {
        margin-right: mixins.vw(45px, 1440);
      }

      @include mixins.mq("full-hd") {
        margin-right: mixins.vw(45px, 1920);
      }
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }

    &::after {
      background-color: colors.$candlelight;
      content: "";
      display: block;
      left: 0;
      position: absolute;
      transition: width animation.$basic-transition-timing;
      width: 0;
      bottom: mixins.vw(-2px, 1024);
      height: mixins.vw(2px, 1024);

      @include mixins.mq("desktop") {
        bottom: mixins.vw(-10px, 1440);
        height: mixins.vw(3px, 1440);
      }

      @include mixins.mq("full-hd") {
        bottom: mixins.vw(-10px, 1920);
        height: mixins.vw(3px, 1920);
      }
    }

    &.active {
      &::after {
        width: 100%;
      }
    }
  }
}
</style>
