<template>
  <div class="canvas">
    <div class="model-wrapper" :class="{male: stateGender === '1', female: stateGender === '2'}" id="canvas">
      <img v-if="stateGender === '1'" src="/img/models/model-male.png" class="model" alt="">
      <img v-else-if="stateGender === '2'" :src="constructFemaleModelLink()" class="model" alt="">

      <img :src="constructShirtImageLink()" class="clothes shirt" :class="{layeroverride: checkForLayerOverride()}" alt="">

      <img :src="constructHighVisibilityClothingImageLink()" class="clothes high-visibility-clothing" alt="">

      <img :src="constructVestImageLink()" class="clothes vest" alt="">

      <img :src="constructJacketImageLink()" class="clothes jacket" alt="">

      <img :src="constructPantsImageLink()" class="clothes pants" alt="">

      <img :src="constructShoesImageLink()" class="clothes shoes" alt="">

      <img :src="constructCardImageLink()" class="clothes card" alt="">

      <img :src="constructHatImageLink()" class="clothes hat" alt="">

      <img src="/img/items/visuals/shadow.png" class="clothes shadow" alt="">
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    stateGender () {
      return this.$store.state.genderId
    },
    stateDepartment () {
      return this.$store.state.departmentId
    }
  },
  name: 'TheCanvas',
  data: function () {
    return {
    }
  },
  methods: {
    imageExists (url) {
      const http = new XMLHttpRequest()
      http.open('HEAD', url, false)
      http.send()
      return http.status !== 404
    },
    checkForLayerOverride () {
      const layerOverrideIds = ['51', '52']
      if (this.$store.state.selectedClothingItems[2][0]) {
        return layerOverrideIds.includes(this.$store.state.selectedClothingItems[2][0].id)
      }
    },
    constructShirtImageLink () {
      if (!this.$store.state.genderId) {
        return '/img/transparent.png'
      }

      const shirtsWithoutNeckwear = ['55', '61', '54']
      const visualIdsVestsWithLongSleeves = [17, 45, 72, 90]
      const visualIdsHighVisibilityClothingWithLongSleeves = [33, 19]
      const genderSubstring = (this.stateGender === '1') ? 'male/' : 'female/'
      const neckwearSubstring = (this.stateGender === '1') ? '-tie' : '-scarf'
      let imageUrl = '/img/items/visuals/'

      // Add gender string
      imageUrl = imageUrl + genderSubstring

      // Add base product image
      imageUrl = imageUrl + this.$store.state.selectedClothingItems[3][0].visualId

      // Neckwear condition
      if (
        // ... the shirt should have a neckwear image
        shirtsWithoutNeckwear.includes(this.$store.state.selectedClothingItems[3][0].id) === false &&
        (
          // ... the tie is selected
          this.$store.state.selectedClothingItems[8].find(elem => elem.id === '5') ||
          // ... the scarf is selected
          this.$store.state.selectedClothingItems[8].find(elem => elem.id === '17')
        )
      ) {
        imageUrl = imageUrl + neckwearSubstring
      }

      // Jacket version of shirt condition
      if (
        // ... a jacket is selected and the selected jacket is not the ware mover vest
        (this.$store.state.selectedClothingItems[1][0] && this.$store.state.selectedClothingItems[1][0].visualId !== 68) ||
        // ... an item is selected on the vest layer and the selected item has long sleeves
        (this.$store.state.selectedClothingItems[2][0] && visualIdsVestsWithLongSleeves.includes(this.$store.state.selectedClothingItems[2][0].visualId) === false) ||
        // ... high-visibility-clothing is selected and the item has long sleeves
        (this.$store.state.selectedClothingItems[5][0] && visualIdsHighVisibilityClothingWithLongSleeves.includes(this.$store.state.selectedClothingItems[5][0].visualId) === true)
      ) {
        imageUrl = imageUrl + '-jacket'
      }

      // Add file extension
      imageUrl = imageUrl + '.png'

      // Default if no shirt is selected or image is not available
      // If no jacket is selected or the selected jacket is not a Kasack, wear a certain shirt as default
      if (
        !this.$store.state.selectedClothingItems[1][0] ||
        (this.$store.state.selectedClothingItems[1][0] && !['29', '30'].includes(this.$store.state.selectedClothingItems[1][0].visualId))
      ) {
        if (!this.imageExists(imageUrl)) {
          if (this.stateGender === '1') {
            imageUrl = '/img/items/visuals/male/41.png'
          } else {
            imageUrl = '/img/items/visuals/female/82.png'
          }
        }
      }

      return imageUrl
    },
    constructVestImageLink () {
      if (!this.$store.state.genderId || this.$store.state.selectedClothingItems[1][0]) {
        return '/img/transparent.png'
      }

      let imageUrl
      if (this.$store.state.selectedClothingItems[2][0]) {
        if (this.stateGender === '1') {
          imageUrl = '/img/items/visuals/male/' + this.$store.state.selectedClothingItems[2][0].visualId + '.png'
        } else {
          imageUrl = '/img/items/visuals/female/' + this.$store.state.selectedClothingItems[2][0].visualId + '.png'
        }
      } else {
        imageUrl = '/img/transparent.png'
      }

      return imageUrl
    },
    constructHighVisibilityClothingImageLink () {
      if (!this.$store.state.genderId) {
        return '/img/transparent.png'
      }

      let imageUrl
      if (this.$store.state.selectedClothingItems[5][0]) {
        if (this.stateGender === '1') {
          imageUrl = '/img/items/visuals/male/' + this.$store.state.selectedClothingItems[5][0].visualId + '.png'
        } else {
          imageUrl = '/img/items/visuals/female/' + this.$store.state.selectedClothingItems[5][0].visualId + '.png'
        }
      } else {
        imageUrl = '/img/transparent.png'
      }

      return imageUrl
    },
    constructJacketImageLink () {
      if (!this.$store.state.genderId) {
        return '/img/transparent.png'
      }

      if (!this.$store.state.selectedClothingItems[1][0] && !this.$store.state.selectedClothingItems[5][0]) {
        return '/img/transparent.png'
      }

      const imageDirectory = this.stateGender === '1' ? '/img/items/visuals/male/' : '/img/items/visuals/female/'

      if (!this.$store.state.selectedClothingItems[1][0] &&
        (this.$store.state.selectedClothingItems[5][0].visualId === 63 ||
          this.$store.state.selectedClothingItems[5][0].visualId === 62)) {
        return imageDirectory + this.$store.state.selectedClothingItems[5][0].visualId + '.png'
      }

      if (
        this.$store.state.selectedClothingItems[5][0] &&
        this.$store.state.selectedClothingItems[5][0].visualId !== 63 &&
        this.$store.state.selectedClothingItems[5][0].visualId !== 62
      ) {
        return imageDirectory + this.$store.state.selectedClothingItems[5][0].visualId + '.png'
      } else {
        if (this.$store.state.selectedClothingItems[1][0]) {
          return imageDirectory + this.$store.state.selectedClothingItems[1][0].visualId + '.png'
        }
      }
    },
    constructPantsImageLink () {
      if (!this.$store.state.genderId) {
        return '/img/transparent.png'
      }

      let imageUrl
      if (this.$store.state.selectedClothingItems[4][0]) {
        if (this.stateGender === '1') {
          imageUrl = '/img/items/visuals/male/' + this.$store.state.selectedClothingItems[4][0].visualId + '.png'
        } else {
          imageUrl = '/img/items/visuals/female/' + this.$store.state.selectedClothingItems[4][0].visualId + '.png'
        }
      }

      if (!this.imageExists(imageUrl)) {
        if (this.stateGender === '1') {
          imageUrl = '/img/items/visuals/male/53.png'
        } else {
          imageUrl = '/img/items/visuals/female/59.png'
        }
      }

      return imageUrl
    },
    constructShoesImageLink () {
      if (!this.$store.state.genderId) {
        return '/img/transparent.png'
      }

      let imageUrl = '/img/items/visuals/'
      const genderSubstring = (this.stateGender === '1') ? 'male/' : 'female/'

      imageUrl = imageUrl + genderSubstring
      if (this.$store.state.selectedClothingItems[9][0]) {
        imageUrl = imageUrl + this.$store.state.selectedClothingItems[9][0].visualId + '.png'
      }

      if (!this.imageExists(imageUrl)) {
        if (this.stateGender === '1') {
          imageUrl = '/img/items/visuals/male/6.png'
        } else {
          imageUrl = '/img/items/visuals/female/7.png'
        }
      }

      return imageUrl
    },
    constructCardImageLink () {
      if (!this.$store.state.genderId) {
        return '/img/transparent.png'
      }

      let imageUrl = '/img/items/visuals/'
      const genderSubstring = (this.stateGender === '1') ? 'male/' : 'female/'

      imageUrl = imageUrl + genderSubstring
      if (this.$store.state.selectedClothingItems[11][0]) {
        imageUrl = imageUrl + this.$store.state.selectedClothingItems[11][0].visualId + '.png'
      } else {
        imageUrl = '/img/transparent.png'
      }

      return imageUrl
    },
    constructHatImageLink () {
      if (!this.$store.state.genderId) {
        return '/img/transparent.png'
      }

      let imageUrl = '/img/items/visuals/'
      const genderSubstring = (this.stateGender === '1') ? 'male/' : 'female/'

      imageUrl = imageUrl + genderSubstring
      if (this.$store.state.selectedClothingItems[7][0]) {
        imageUrl = imageUrl + this.$store.state.selectedClothingItems[7][0].visualId + '.png'
        return imageUrl
      } else {
        return '/img/transparent.png'
      }
    },
    constructFemaleModelLink () {
      const shirtsWithShortSleeves = [82]
      const hasSkirt = (this.$store.state.selectedClothingItems[4][0].visualId === 60) ? '-skirt' : ''

      if (shirtsWithShortSleeves.includes(this.$store.state.selectedClothingItems[3][0].visualId)) {
        return '/img/models/model-female-shortsleeve' + hasSkirt + '.png'
      } else {
        return '/img/models/model-female' + hasSkirt + '.png'
      }
    }
  }
}
</script>

<style lang="scss">
@use "../scss/mixins";

.canvas {
  .model-wrapper {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .model,
    .clothes {
      display: block;
      max-height: calc(100vh - #{mixins.vw(100px, 1024)});

      @include mixins.mq("desktop") {
        max-height: calc(100vh - #{mixins.vw(100px, 1440)});
      }

      @include mixins.mq("full-hd") {
        max-height: calc(100vh - #{mixins.vw(100px, 1920)});
      }
    }

    .clothes {
      position: absolute;
      top: 0;
      left: 0;
    }

    &.female,
    &.male {
      .high-visibility-clothing {
        z-index: 5;
      }

      .jacket {
        z-index: 4;
      }

      .pants {
        z-index: 1;
      }

      .vest {
        z-index: 3;
      }

      .card {
        z-index: 6;
      }

      .shirt {
        z-index: 2;

        &.layeroverride {
          z-index: 3;
        }
      }
    }
  }
}
</style>
