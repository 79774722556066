<template>
  <p class="item-title">
    {{ itemObject.title }}
  </p>

  <p class="item-color" v-if="itemObject.color">{{ itemObject.color }}</p>

  <span v-if="isPreselected" class="badge">Vorauswahl</span>
  <button v-else class="delete-button" :class="buttonClass" @click="deleteProduct(itemObject)">Produkt löschen</button>
</template>

<script>
export default {
  name: 'ConfigurationOverviewItem',
  props: {
    title: String,
    id: String,
    isPreselected: Boolean,
    itemObject: Object
  },
  inheritAttrs: false,
  computed: {
    buttonClass () {
      return 'delete_item_' + this.$props.id
    },
    isSelectedItem () {
      for (const item of this.$store.state.selectedClothingItems[this.$store.state.categoryId]) {
        if (item.id === this.itemObject.id) {
          return true
        }
      }

      return false
    }
  },
  methods: {
    deleteProduct (itemObject) {
      // if the selected item is an item inside a preselection category
      // but not the preselected item itself,
      // delete the item and readd the preselected one
      if (
        this.isSelectedItem === true &&
        this.$store.state.categories[this.itemObject.category - 1].hasPreselection === true &&
        this.itemObject.isPreselected === false
      ) {
        this.$store.commit('removeClothingItem', itemObject)

        for (const item of this.$store.state.availableClothingItems[this.itemObject.category]) {
          if (item.isPreselected) {
            this.$store.commit('addClothingItem', item)

            return
          }
        }

        return
      }

      this.$store.commit('removeClothingItem', itemObject)
    }
  }
}
</script>

<style lang="scss" scoped>
@use '../scss/animation';
@use '../scss/colors';
@use '../scss/mixins';

li {
  align-items: center;
  display: flex;
  position: relative;
  line-height: mixins.vw(30px, 1024);
  margin-bottom: mixins.vw(12px, 1024);

  @include mixins.mq("desktop") {
    line-height: mixins.vw(30px, 1440);
    margin-bottom: mixins.vw(12px, 1440);
  }

  @include mixins.mq("full-hd") {
    line-height: mixins.vw(30px, 1920);
    margin-bottom: mixins.vw(12px, 1920);
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
    margin-right: mixins.vw(35px, 1024);
    max-height: mixins.vw(44px, 1024);
    max-width: mixins.vw(45px, 1024);

    @include mixins.mq("desktop") {
      margin-right: mixins.vw(35px, 1440);
      max-height: mixins.vw(44px, 1440);
      max-width: mixins.vw(45px, 1440);
    }

    @include mixins.mq("full-hd") {
      margin-right: mixins.vw(35px, 1920);
      max-height: mixins.vw(44px, 1920);
      max-width: mixins.vw(45px, 1920);
    }
  }

  .badge {
    background-color: colors.$mercury;
    line-height: 140%;
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: mixins.vw(9px, 1024);
    padding: mixins.vw(2px, 1024);

    @include mixins.mq("desktop") {
      bottom: initial;
      top: 50%;
      transform: translateY(-50%);
      font-size: mixins.vw(10px, 1440);
      padding: mixins.vw(4px, 1440);
    }

    @include mixins.mq("full-hd") {
      font-size: mixins.vw(10px, 1920);
      padding: mixins.vw(4px, 1920);
    }
  }

  .delete-button {
    background-color: white;
    border: none;
    box-shadow: none;
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    outline: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color animation.$basic-transition-timing;
    margin: 0 auto mixins.vw(32px, 1024) auto;
    border: mixins.vw(50px, 1024);
    height: mixins.vw(32px, 1024);
    width: mixins.vw(32px, 1024);

    @include mixins.mq("desktop") {
      margin: 0 auto mixins.vw(32px, 1440) auto;
      border: mixins.vw(50px, 1440);
      height: mixins.vw(32px, 1440);
      width: mixins.vw(32px, 1440);
    }

    @include mixins.mq("full-hd") {
      margin: 0 auto mixins.vw(32px, 1920) auto;
      border: mixins.vw(50px, 1920);
      height: mixins.vw(32px, 1920);
      width: mixins.vw(32px, 1920);
    }

    &:hover,
    &:focus {
      background-color: colors.$mercury;
    }

    &:active {
      background-color: lighten(colors.$mercury, 10%);
    }

    &::before {
      background-image: url("/img/icon-close.svg");
      background-size: 100% 100%;
      content: "";
      display: blocK;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      height: mixins.vw(12px, 1024);
      width: mixins.vw(12px, 1024);

      @include mixins.mq("desktop") {
        height: mixins.vw(12px, 1440);
        width: mixins.vw(12px, 1440);
      }

      @include mixins.mq("full-hd") {
        height: mixins.vw(12px, 1920);
        width: mixins.vw(12px, 1920);
      }
    }
  }

  .content {
    background-color: white;
    width: 100%;
    padding: mixins.vw(16px, 1024);

    @include mixins.mq("desktop") {
      padding: mixins.vw(16px, 1440);
    }

    @include mixins.mq("full-hd") {
      padding: mixins.vw(16px, 1920);
    }

    &__item {
      position: relative;
      width: 100%;

      &:not(&:last-of-type) {
        margin-bottom: mixins.vw(16px, 1024);

        @include mixins.mq("desktop") {
          margin-bottom: mixins.vw(16px, 1440);
        }

        @include mixins.mq("full-hd") {
          margin-bottom: mixins.vw(16px, 1920);
        }
      }
    }
  }

  .item-title {
    font-weight: 600;
    font-size: mixins.vw(13px, 1024);

    @include mixins.mq("desktop") {
      font-size: mixins.vw(16px, 1440);
    }

    @include mixins.mq("full-hd") {
      font-size: mixins.vw(16px, 1920);
    }
  }

  .item-color {
    font-size: mixins.vw(13px, 1024);

    @include mixins.mq("desktop") {
      font-size: mixins.vw(16px, 1440);
    }

    @include mixins.mq("full-hd") {
      font-size: mixins.vw(16px, 1920);
    }
  }
}
</style>
