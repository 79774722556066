<template>
  <TheStartScreen />

  <div class="interface">
    <TheConfigurationView />

    <TheConfigurationBuilder />
  </div>

  <TheConfigurationOverview />

  <TheConfigurationResetNotice />
</template>

<script>
import TheConfigurationView from './components/TheConfigurationView.vue'
import TheConfigurationBuilder from './components/TheConfigurationBuilder.vue'
import TheConfigurationOverview from './components/TheConfigurationOverview.vue'
import TheConfigurationResetNotice from './components/TheConfigurationResetNotice'
import TheStartScreen from './components/TheStartScreen'

export default {
  name: 'App',
  components: {
    TheStartScreen,
    TheConfigurationBuilder,
    TheConfigurationOverview,
    TheConfigurationView,
    TheConfigurationResetNotice
  }
}
</script>

<style lang="scss">
@use './scss/colors';

/* Franklin Gothic Regular */
@font-face {
  font-family: 'Franklin Gothic';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/franklin-gothic-regular.ttf') format('truetype') /* Safari, Android, iOS */
}

/* Franklin Gothic Bold */
@font-face {
  font-family: 'Franklin Gothic';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/franklin-gothic-bold.ttf') format('truetype') /* Safari, Android, iOS */
}

#app {
  font-family: "Franklin Gothic", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.interface {
  display: grid;
  max-height: 100vh;
  overflow-y: hidden;
  grid-template-columns: 1.1fr 1.9fr;

  @media (min-width: 1440px) {
    grid-template-columns: 1fr 2fr;
  }
}

.hidden {
  display: none;
}
</style>
