<template>
  <button
    @click="clothingCategoryTileClickHandler(categoryId)"
    class="clothing-category-tile"
    :class="{active: isCurrentCategory, disabled: hasNoAvailableItems}">
    <div class="clothing-category-tile__button">
      <img class="clothing-category-tile__image" :src="image" alt="" />

      <div
        class="clothing-category-tile__amount"
        :class="{show: categoryItemAmount}">
        <span class="clothing-category-tile__amount__text">
          {{categoryItemAmount}}
        </span>
      </div>

      <div v-if="categoryHasPreselection" class="clothing-category-tile__badge">
        <Tooltip
          message="Für diesen Bereich ist ein Artikel vorausgewählt"
          icontype="info"/>

        Vorauswahl
      </div>
    </div>

    <p class="clothing-category-tile__title">{{title}}</p>
  </button>
</template>

<script>
import Tooltip from './Tooltip'

export default {
  components: {
    Tooltip
  },
  computed: {
    isCurrentCategory: function () {
      return this.$store.state.categoryId === this.categoryId
    },
    categoryHasPreselection: function () {
      if (this.$store.state.availableClothingItems[this.categoryId]) {
        for (const item of this.$store.state.availableClothingItems[this.categoryId]) {
          if (item.isPreselected) {
            return true
          }
        }
      }

      return false
    },
    hasNoAvailableItems: function () {
      return this.$store.state.availableClothingItems[this.categoryId] === undefined
    },
    categoryItemAmount: function () {
      return this.$store.state.selectedClothingItems[this.categoryId].length
    }
  },
  methods: {
    clothingCategoryTileClickHandler (id) {
      if (this.hasNoAvailableItems) {
        return
      }

      const itemWrapper = document.querySelector('.clothing-item-selection__items-wrapper__inner')

      this.$store.commit('updateCategory', id)
      this.$store.commit('setSliderPosition', 0)
      this.$store.commit('setSliderEndPosition', null)

      itemWrapper.style.transform = 'translateX(0)'
    }
  },
  name: 'TheClothingCategoryTile',
  props: {
    image: String,
    title: String,
    categoryId: String
  }
}
</script>

<style scoped lang="scss">
@use "../scss/animation";
@use "../scss/colors";
@use "../scss/mixins";

.clothing-category-tile {
  color: colors.$midnight;
  position: relative;
  border: none;
  background-color: transparent;

  &:not(.active) {
    &:hover {
      .clothing-category-tile {
        &__button {
          box-shadow: mixins.vw(0 5px 25px, 1024) rgba(0, 0, 0, 0.15);

          @include mixins.mq("desktop") {
            box-shadow: mixins.vw(0 5px 25px, 1440) rgba(0, 0, 0, 0.15);
          }

          @include mixins.mq("full-hd") {
            box-shadow: mixins.vw(0 5px 25px, 1920) rgba(0, 0, 0, 0.15);
          }
        }
      }
    }
  }

  &.active {
    .clothing-category-tile {
      &__button {
        box-shadow: mixins.vw(0 0 0 3px, 1024) colors.$candlelight;

        @include mixins.mq("desktop") {
          box-shadow: mixins.vw(0 0 0 3px, 1440) colors.$candlelight;
        }

        @include mixins.mq("full-hd") {
          box-shadow: mixins.vw(0 0 0 3px, 1920) colors.$candlelight;
        }
      }
    }
  }

  &.disabled {
    display: none;
  }

  &__amount {
    background-color: rgba(colors.$midnight, 0.9);
    display: block;
    opacity: 0;
    position: absolute;
    transform: scale(0);
    transition: opacity animation.$basic-transition-timing, transform animation.$basic-transition-timing;
    border-radius: mixins.vw(16px, 1024);
    height: mixins.vw(13px, 1024);
    width: mixins.vw(13px, 1024);
    top: mixins.vw(8px, 1024);
    right: mixins.vw(8px, 1024);

    @include mixins.mq("desktop") {
      border-radius: mixins.vw(16px, 1440);
      height: mixins.vw(16px, 1440);
      width: mixins.vw(16px, 1440);
      top: mixins.vw(8px, 1440);
      right: mixins.vw(8px, 1440);
    }

    @include mixins.mq("full-hd") {
      border-radius: mixins.vw(16px, 1920);
      height: mixins.vw(16px, 1920);
      width: mixins.vw(16px, 1920);
      top: mixins.vw(8px, 1920);
      right: mixins.vw(8px, 1920);
    }

    &.show {
      opacity: 1;
      transform: scale(1);
    }

    &__text {
      color: white;
      font-weight: 600;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: mixins.vw(10px, 1024);

      @include mixins.mq("desktop") {
        font-size: mixins.vw(16px, 1440);
      }

      @include mixins.mq("full-hd") {
        font-size: mixins.vw(16px, 1920);
      }
    }
  }

  &__button {
    background-color: white;
    border: 1px solid colors.$mercury;
    box-shadow: none;
    cursor: pointer;
    position: relative;
    transition: box-shadow animation.$basic-transition-timing;
    border-radius: mixins.vw(10px, 1024);
    margin-bottom: mixins.vw(8px, 1024);
    height: mixins.vw(90px, 1024);
    width: mixins.vw(90px, 1024);

    @include mixins.mq("desktop") {
      border-radius: mixins.vw(10px, 1440);
      margin-bottom: mixins.vw(8px, 1440);
      height: mixins.vw(140px, 1440);
      width: mixins.vw(140px, 1440);
    }

    @include mixins.mq("full-hd") {
      border-radius: mixins.vw(10px, 1920);
      margin-bottom: mixins.vw(8px, 1920);
      height: mixins.vw(140px, 1920);
      width: mixins.vw(140px, 1920);
    }
  }

  &__badge {
    background-color: colors.$mercury;
    display: inline-block;
    position: absolute;
    border-radius: mixins.vw(4px, 1024);
    font-size: mixins.vw(7px, 1024);
    left: mixins.vw(8px, 1024);
    padding: mixins.vw(4px, 1024);
    bottom: 0;

    @include mixins.mq("desktop") {
      border-radius: mixins.vw(4px, 1440);
      font-size: mixins.vw(10px, 1440);
      left: mixins.vw(8px, 1440);
      padding: mixins.vw(4px, 1440);
      top: mixins.vw(8px, 1440);
      bottom: unset;
    }

    @include mixins.mq("full-hd") {
      border-radius: mixins.vw(4px, 1920);
      font-size: mixins.vw(10px, 1920);
      left: mixins.vw(8px, 1920);
      padding: mixins.vw(4px, 1920);
      top: mixins.vw(8px, 1920);
    }

    &:hover {
      .tooltip {
        animation: fade-in-tooltip 0.25s forwards;
        animation-delay: 2s;
      }
    }

    .tooltip {
      pointer-events: none;
      position: absolute;
      top: 50%;
      opacity: 0;
      transition: transform animation.$basic-transition-timing, opacity animation.$basic-transition-timing;
      transform: translateY(calc(-50% - 10px));
      box-shadow: mixins.vw(0 5px 25px, 1024) rgba(0, 0, 0, 0.15);
      right: mixins.vw(70px, 1024);

      @include mixins.mq("desktop") {
        box-shadow: mixins.vw(0 5px 25px, 1440) rgba(0, 0, 0, 0.15);
        right: mixins.vw(70px, 1440);
      }

      @include mixins.mq("full-hd") {
        box-shadow: mixins.vw(0 5px 25px, 1920) rgba(0, 0, 0, 0.15);
        right: mixins.vw(70px, 1920);
      }
    }
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    object-fit: contain;
  }

  &__title {
    text-align: center;
    transform: translateY(0);
    transition: transform animation.$basic-transition-timing;
    font-size: mixins.vw(12px, 1024);

    @include mixins.mq("desktop") {
      font-size: mixins.vw(12px, 1440);
    }

    @include mixins.mq("full-hd") {
      font-size: mixins.vw(12px, 1920);
    }
  }
}

@keyframes fade-in-tooltip {
  from {
    transform: translateY(calc(-50% - 10px));
    opacity: 0;
  }

  to {
    transform: translateY(-50%);
    opacity: 1;
  }
}
</style>
