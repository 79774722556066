<template>
  <li v-show="selectedItemsForCategory.length >= 1">
    <img :src="image" alt="{{image}}">

    <div class="content">
      <div
        v-for="item in selectedItemsForCategory"
        :key="item.title"
        class="content__item">
        <ConfigurationOverviewItem
          v-bind="item"
          :item-object="item"/>
      </div>
    </div>
  </li>
</template>

<script>
import ConfigurationOverviewItem from './ConfigurationOverviewItem'

export default {
  name: 'ConfigurationOverviewCategory',
  components: {
    ConfigurationOverviewItem
  },
  computed: {
    selectedItemsForCategory () {
      const item = this.$store.state.selectedClothingItems[this.id]
      let result = item
      if (item[0]) {
        if (item[0].hideOnCheckout === true) {
          result = false
        }
      }

      return result
    }
  },
  props: {
    title: String,
    image: String,
    items: Array,
    id: String
  }
}
</script>

<style lang="scss" scoped>
@use '../scss/animation';
@use '../scss/colors';
@use '../scss/mixins';

li {
  align-items: center;
  display: flex;
  position: relative;
  line-height: mixins.vw(20px, 1024);
  margin-bottom: mixins.vw(12px, 1024);

  @include mixins.mq("desktop") {
    line-height: mixins.vw(20px, 1440);
    margin-bottom: mixins.vw(12px, 1440);
  }

  @include mixins.mq("full-hd") {
    line-height: mixins.vw(20px, 1920);
    margin-bottom: mixins.vw(12px, 1920);
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
    margin-right: mixins.vw(17px, 1024);
    max-height: mixins.vw(44px, 1024);
    max-width: mixins.vw(36px, 1024);

    @include mixins.mq("desktop") {
      margin-right: mixins.vw(35px, 1440);
      max-height: mixins.vw(44px, 1440);
      max-width: mixins.vw(45px, 1440);
    }

    @include mixins.mq("full-hd") {
      margin-right: mixins.vw(35px, 1920);
      max-height: mixins.vw(44px, 1920);
      max-width: mixins.vw(45px, 1920);
    }
  }

  .badge {
    background-color: colors.$mercury;
    line-height: 140%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    font-size: mixins.vw(10px, 1024);
    padding: mixins.vw(4px, 1024);

    @include mixins.mq("desktop") {
      font-size: mixins.vw(10px, 1440);
      padding: mixins.vw(4px, 1440);
    }

    @include mixins.mq("full-hd") {
      font-size: mixins.vw(10px, 1920);
      padding: mixins.vw(4px, 1920);
    }
  }

  .delete-button {
    background-color: white;
    border: none;
    box-shadow: none;
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    outline: none;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color animation.$basic-transition-timing;
    border-radius: mixins.vw(50px, 1024);
    margin-bottom: 0 auto mixins.vw(8px, 1024) auto;
    height: mixins.vw(32px, 1024);
    width: mixins.vw(32px, 1024);

    @include mixins.mq("desktop") {
      border-radius: mixins.vw(50px, 1440);
      margin-bottom: 0 auto mixins.vw(8px, 1440) auto;
      height: mixins.vw(32px, 1440);
      width: mixins.vw(32px, 1440);
    }

    @include mixins.mq("full-hd") {
      border-radius: mixins.vw(50px, 1920);
      margin-bottom: 0 auto mixins.vw(8px, 1920) auto;
      height: mixins.vw(32px, 1920);
      width: mixins.vw(32px, 1920);
    }

    &:hover,
    &:focus {
      background-color: colors.$mercury;
    }

    &:active {
      background-color: lighten(colors.$mercury, 10%);
    }

    &::before {
      background-image: url("/img/icon-close.svg");
      background-size: 100% 100%;
      content: "";
      display: blocK;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      height: mixins.vw(12px, 1024);
      width: mixins.vw(12px, 1024);

      @include mixins.mq("desktop") {
        height: mixins.vw(12px, 1440);
        width: mixins.vw(12px, 1440);
      }

      @include mixins.mq("full-hd") {
        height: mixins.vw(12px, 1920);
        width: mixins.vw(12px, 1920);
      }
    }
  }

  .content {
    background-color: white;
    width: 100%;
    border-radius: mixins.vw(15px, 1024);
    padding: mixins.vw(10px, 1024);

    @include mixins.mq("desktop") {
      border-radius: mixins.vw(15px, 1440);
      padding: mixins.vw(16px, 1440);
    }

    @include mixins.mq("full-hd") {
      border-radius: mixins.vw(15px, 1920);
      padding: mixins.vw(16px, 1920);
    }

    &__item {
      position: relative;
      width: 100%;

      &:not(&:last-of-type) {
        margin-bottom: mixins.vw(16px, 1024);

        @include mixins.mq("desktop") {
          margin-bottom: mixins.vw(16px, 1440);
        }

        @include mixins.mq("full-hd") {
          margin-bottom: mixins.vw(16px, 1920);
        }
      }
    }
  }

  .item-title {
    font-weight: 600;
  }
}
</style>
