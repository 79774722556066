<template>
<div class="configuration-view">
  <img class="logo" src="/img/koetter-logo-blue.svg" alt="">

  <TheCanvas />

  <TheCheckout />
</div>
</template>

<script>
import TheCanvas from './TheCanvas'
import TheCheckout from './TheCheckout'

export default {
  components: {
    TheCanvas,
    TheCheckout
  },
  name: 'TheConfigurationView'
}
</script>

<style scoped lang="scss">
@use "../scss/colors";
@use "../scss/animation";
@use "../scss/mixins";

.configuration-view {
  background-color: colors.$east-bay;
  height: 100vh;
  overflow: hidden;
  position: relative;

  &::before {
    animation: animated-gradient 15s ease infinite;
    background: linear-gradient(90deg, #3f5273, #536481);
    background-size: 400% 400%;
    content: "";
    display: block;
    position: absolute;
    transform: rotate(45deg);
    height: mixins.vw(976px, 1024);
    width: mixins.vw(976px, 1024);
    bottom: mixins.vw(-270px, 1024);
    left: mixins.vw(200px, 1024);

    @include mixins.mq("desktop") {
      height: mixins.vw(976px, 1440);
      width: mixins.vw(976px, 1440);
      bottom: mixins.vw(-270px, 1440);
      left: mixins.vw(200px, 1440);
    }

    @include mixins.mq("full-hd") {
      height: mixins.vw(976px, 1920);
      width: mixins.vw(976px, 1920);
      bottom: mixins.vw(-270px, 1920);
      left: mixins.vw(200px, 1920);
    }
  }

  .logo {
    position: absolute;
    z-index: 4;
    left: mixins.vw(20px, 1024);
    top: mixins.vw(20px, 1024);

    @include mixins.mq("desktop") {
      left: mixins.vw(20px, 1440);
      top: mixins.vw(20px, 1440);
    }

    @include mixins.mq("full-hd") {
      left: mixins.vw(20px, 1920);
      top: mixins.vw(20px, 1920);
    }
  }
}
</style>
