<template>
  <div class="clothing-item-selection">
    <div :class="{show: currentClothingCategory}">
      <div class="clothing-item-selection__text">
        <HeadlineWithArrow :text="headlineText" />

        <p v-if="categoryHasMultiSelect()" class="clothing-item-selection__selection-notice">
          Nur ein Artikel möglich
        </p>
      </div>

      <div
        class="clothing-item-selection__items-wrapper"
        :class="{slider: isClothingItemAmountAboveValue(4), sliderstart: isSliderAtStart, sliderend: isSliderAtEnd}"
      >
        <div class="clothing-item-selection__items-wrapper__inner">
          <TheClothingItemTile
            v-for="item in clothingItemsForActiveCategory"
            :key="item.value"
            :item-object="item"
          />
        </div>

        <div class="slider__controls">
          <button class="back" @click="backButtonClickHandler()"></button>
          <button class="forward" @click="forwardButtonClickHandler()"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheClothingItemTile from './TheClothingItemTile'
import HeadlineWithArrow from './HeadlineWithArrow'

export default {
  computed: {
    currentClothingCategory () {
      return this.$store.state.categoryId
    },
    clothingItemsForActiveCategory () {
      return this.$store.state.availableClothingItems[this.currentClothingCategory]
    },
    isSliderAtStart () {
      return this.$store.state.sliderPosition === 0
    },
    isSliderAtEnd () {
      return this.$store.state.sliderPosition === this.$store.state.sliderEndPosition
    }
  },
  components: {
    TheClothingItemTile,
    HeadlineWithArrow
  },
  data: function () {
    return {
      headlineText: 'Artikel wählen'
    }
  },
  methods: {
    categoryHasMultiSelect () {
      if (this.$store.state.categories[this.$store.state.categoryId - 1]) {
        return this.$store.state.categories[this.$store.state.categoryId - 1].multiselect
      }
    },
    backButtonClickHandler () {
      const sliderPosition = this.$store.state.sliderPosition

      if (sliderPosition !== 0) {
        this.$store.commit('setSliderPosition', sliderPosition - 1)
        this.moveSlider()
      }
    },
    forwardButtonClickHandler () {
      if (this.isClothingItemAmountAboveValue(3)) {
        this.$store.commit('setSliderEndPosition', this.clothingItemsForActiveCategory.length - 5)
      }

      const sliderPosition = this.$store.state.sliderPosition
      const sliderEndPosition = this.$store.state.sliderEndPosition

      if (sliderPosition !== sliderEndPosition) {
        this.$store.commit('setSliderPosition', sliderPosition + 1)
        this.moveSlider()
      }
    },
    moveSlider () {
      const itemWrapper = document.querySelector('.clothing-item-selection__items-wrapper__inner')
      const itemTile = document.querySelector('.clothing-item-tile:nth-child(' + (this.$store.state.sliderPosition + 1) + ')')
      const leftOffset = itemTile.offsetLeft * -1

      console.log('Position', this.$store.state.sliderPosition)
      console.log('itemTile', itemTile)
      console.log('leftOffset', leftOffset)

      itemWrapper.style.transform = 'translateX(' + leftOffset + 'px)'
    },
    isClothingItemAmountAboveValue (value) {
      if (this.clothingItemsForActiveCategory) {
        return this.clothingItemsForActiveCategory.length > value
      }
    }
  },
  name: 'TheClothingItemSelection'
}
</script>

<style scoped lang="scss">
@use '../scss/animation';
@use '../scss/colors';
@use '../scss/mixins';

.clothing-item-selection {
  background-color: colors.$mercury;
  position: relative;
  padding: mixins.vw(24px 0 88px 56px, 1024);

  @include mixins.mq("desktop") {
    padding: mixins.vw(24px 0 88px 56px, 1440);
  }

  @include mixins.mq("full-hd") {
    padding: mixins.vw(24px 0 88px 56px, 1920);
  }

  > div {
    opacity: 0;
    transition: opacity animation.$basic-transition-timing;

    &.show {
      opacity: 1;
    }
  }

  &__items-wrapper {
    overflow: hidden;
    position: relative;
    height: mixins.vw(290px, 1024);
    width: mixins.vw(604px, 1024);
    padding: mixins.vw(12px, 1024);

    @include mixins.mq("desktop") {
      height: mixins.vw(290px, 1440);
      width: mixins.vw(810px, 1440);
      padding: mixins.vw(12px, 1440);
    }

    @include mixins.mq("full-hd") {
      height: mixins.vw(290px, 1920);
      width: mixins.vw(804px, 1920);
      padding: mixins.vw(12px, 1920);
    }

    &::after,
    &::before {
      position: absolute;
      content: "";
      top: 0;
      height: 100%;
      opacity: 0;
      width: 50%;
      display: block;
      pointer-events: none;
      z-index: 3;
      transition: opacity animation.$long-transition-timing;
    }

    &::after {
      // background: linear-gradient(90deg, rgba(232,232,232,0) 0%, rgba(232,232,232,1) 100%);
      right: 0;
    }

    &::before {
      // background: linear-gradient(240deg, rgba(232,232,232,0) 0%, rgba(232,232,232,1) 100%);
      left: 0;
    }

    &:not(.slider) {
      .slider__controls {
        display: none;
      }
    }

    &.slider {
      &:not(.sliderstart) {
        &::before {
          opacity: 1;
        }

        button {
          &.back {
            transform: scale(1);
          }
        }
      }

      &:not(.sliderend) {
        &::after {
          opacity: 1;
        }

        button {
          &.forward {
            transform: scale(1);
          }
        }
      }

      .slider {
        &__controls {
          position: absolute;
          display: flex;
          justify-content: space-between;
          z-index: 4;
          pointer-events: none;
          width: calc(100% - #{mixins.vw(80px, 1024)});
          left: mixins.vw(40px, 1024);
          top: mixins.vw(78px, 1024);

          @include mixins.mq("desktop") {
            left: mixins.vw(40px, 1440);
            top: mixins.vw(78px, 1440);
            width: calc(100% - #{mixins.vw(80px, 1024)});
          }

          @include mixins.mq("full-hd") {
            left: mixins.vw(40px, 1920);
            top: mixins.vw(78px, 1920);
            width: calc(100% - #{mixins.vw(80px, 1024)});
          }

          button {
            cursor: pointer;
            background-color: white;
            pointer-events: auto;
            position: relative;
            transform: scale(0);
            transition: background-color animation.$basic-transition-timing, transform animation.$basic-transition-timing;
            box-shadow: mixins.vw(20 5px 25px, 1024) rgba(0, 0, 0, 0.15);
            border: mixins.vw(2px, 1024) solid colors.$midnight;
            height: mixins.vw(48px, 1024);
            width: mixins.vw(48px, 1024);

            @include mixins.mq("desktop") {
              box-shadow: mixins.vw(20 5px 25px, 1440) rgba(0, 0, 0, 0.15);
              border: mixins.vw(2px, 1440) solid colors.$midnight;
              height: mixins.vw(48px, 1440);
              width: mixins.vw(48px, 1440);
            }

            @include mixins.mq("full-hd") {
              box-shadow: mixins.vw(20 5px 25px, 1920) rgba(0, 0, 0, 0.15);
              border: mixins.vw(2px, 1920) solid colors.$midnight;
              height: mixins.vw(48px, 1920);
              width: mixins.vw(48px, 1920);
            }

            &:hover,
            &:focus {
              background-color: colors.$midnight;
              color: white;

              &::after,
              &::before {
                background-color: white;
              }
            }

            &::after,
            &::before {
              background-color: colors.$midnight;
              content: '';
              display: block;
              position: absolute;
              top: 50%;
              transform-origin: left;
              transition: background-color animation.$basic-transition-timing;
              height: mixins.vw(2px, 1024);
              left: calc( 50% - #{mixins.vw(6px, 1024)});
              width: mixins.vw(15px, 1024);

              @include mixins.mq("desktop") {
                height: mixins.vw(2px, 1440);
                left: calc( 50% - #{mixins.vw(6px, 1440)});
                width: mixins.vw(15px, 1440);
              }

              @include mixins.mq("full-hd") {
                height: mixins.vw(2px, 1920);
                left: calc( 50% - #{mixins.vw(6px, 1920)});
                width: mixins.vw(15px, 1920);
              }
            }

            &::after {
              transform: rotate(45deg) translateY(-50%);
            }

            &::before {
              transform: rotate(-45deg) translateY(-50%);
            }

            &:last-child {
              &::after,
              &::before {
                left: mixins.vw(28px, 1024);

                @include mixins.mq("desktop") {
                  left: mixins.vw(28px, 1440);
                }

                @include mixins.mq("full-hd") {
                  left: mixins.vw(28px, 1920);
                }
              }

              &::after {
                transform: rotate(135deg) translateY(50%);
              }

              &::before {
                transform: rotate(-135deg) translateY(50%);
              }
            }
          }
        }
      }
    }

    &__inner {
      display: flex;
      position: absolute;
      transition: transform animation.$basic-transition-timing;
      gap: mixins.vw(10px, 1024);

      @include mixins.mq("desktop") {
        gap: mixins.vw(30px, 1440);
      }

      @include mixins.mq("full-hd") {
        gap: mixins.vw(30px, 1920);
      }
    }
  }

  &__selection-notice {
    color: colors.$midnight;
    line-height: 115%;
    font-size: mixins.vw(12px, 1024);

    @include mixins.mq("desktop") {
      font-size: mixins.vw(12px, 1440);
    }

    @include mixins.mq("full-hd") {
      font-size: mixins.vw(12px, 1920);
    }
  }

  &__text {
    display: flex;
    gap: mixins.vw(27px, 1024);

    @include mixins.mq("desktop") {
      gap: mixins.vw(27px, 1440);
    }

    @include mixins.mq("full-hd") {
      gap: mixins.vw(27px, 1920);
    }
  }
}
</style>
