<template>
  <div class="gender-selection" :class="{show: isSubdepartmentSet}">
    <button
      @click="updateGender(gender.id, gender.title)"
      v-for="gender in genders"
      class="gender-selection__gender"
      :class="{active: checkActiveState(gender.id)}"
      :key="gender.id"
      :gender-id="gender.id">
      {{ gender.title }}
    </button>

    <span
      class="hint"
      :class="{hide: isGenderSet}">
      Geschlecht wählen
    </span>
  </div>
</template>

<script>
import CategoryDataService from '../service/CategoryDataService'

export default {
  computed: {
    isSubdepartmentSet () {
      return this.$store.state.subdepartmentId
    },
    isGenderSet () {
      return this.$store.state.genderId
    }
  },
  data: function () {
    const categoryDataService = new CategoryDataService()

    return {
      genders: categoryDataService.getGenders()
    }
  },
  methods: {
    updateGender (genderId, genderTitle) {
      const state = this.$store.state
      if (this.isGenderSet && this.checkActiveState(genderId) === false) {
        this.$store.commit('setResetNoticeOpen', true)
        this.$store.commit('writeResetCache',
          {
            departmentId: state.departmentId,
            departmentTitle: state.departmentTitle,
            genderId: genderId,
            genderTitle: genderTitle,
            subdepartmentId: state.subdepartmentId,
            subdepartmentTitle: state.subdepartmentTitle
          }
        )
      } else {
        this.$store.commit('setGenderAndAvailableProducts', { genderId, genderTitle })
      }
    },
    checkActiveState (genderId) {
      return genderId === this.$store.state.genderId
    }
  },
  name: 'TheGenderSelection'
}
</script>

<style scoped lang="scss">
@use '../scss/animation';
@use '../scss/colors';
@use '../scss/mixins';

.gender-selection {
  display: flex;
  pointer-events: none;
  position: relative;
  opacity: 0;
  transition: opacity animation.$basic-transition-timing;

  &.show {
    pointer-events: auto;
    opacity: 1;

    .hint {
      display: block;
      font-weight: 600;
      line-height: 115%;
      opacity: 1;
      left: mixins.vw(12px, 1024);
      top: mixins.vw(40px, 1024);
      font-size: mixins.vw(10px, 1024);

      @include mixins.mq("desktop") {
        left: mixins.vw(48px, 1440);
        top: mixins.vw(40px, 1440);
        font-size: mixins.vw(14px, 1440);
      }

      @include mixins.mq("full-hd") {
        left: mixins.vw(18px, 1920);
        top: mixins.vw(35px, 1920);
        font-size: mixins.vw(14px, 1920);
      }

      &::after {
        background-image: url("/img/icon-arrow-curve-up.svg");
        background-size: 100% 100%;
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: mixins.vw(12px, 1024);
        width: mixins.vw(6px, 1024);
        left: mixins.vw(-16px, 1024);

        @include mixins.mq("desktop") {
          height: mixins.vw(22px, 1440);
          width: mixins.vw(16px, 1440);
          left: mixins.vw(-26px, 1440);
        }

        @include mixins.mq("full-hd") {
          height: mixins.vw(22px, 1920);
          width: mixins.vw(16px, 1920);
          left: mixins.vw(-26px, 1920);
        }
      }
    }
  }

  .hint {
    &,
    &.hide {
      opacity: 0;
      position: absolute;
      transition: opacity animation.$basic-transition-timing, top animation.$basic-transition-timing;
      top: mixins.vw(80px, 1024);

      @include mixins.mq("desktop") {
        top: mixins.vw(40px, 1440);
      }

      @include mixins.mq("full-hd") {
        top: mixins.vw(40px, 1920);
      }
    }
  }

  &__gender {
    background-color: transparent;
    border: none;
    color: colors.$midnight;
    cursor: pointer;
    position: relative;
    line-height: 140%;
    padding-left: mixins.vw(12px, 1024);
    margin-right: mixins.vw(24px, 1024);
    font-size: mixins.vw(12px, 1024);

    @include mixins.mq("desktop") {
      padding-left: mixins.vw(20px, 1440);
      margin-right: mixins.vw(24px, 1440);
      font-size: mixins.vw(16px, 1440);
    }

    @include mixins.mq("full-hd") {
      padding-left: mixins.vw(20px, 1920);
      margin-right: mixins.vw(24px, 1920);
      font-size: mixins.vw(16px, 1920);
    }

    &.active,
    &:hover {
      &::after {
        width: 100%;
      }
    }

    &::after {
      background-color: colors.$midnight;
      content: "";
      display: block;
      left: 0;
      position: absolute;
      transition: width animation.$basic-transition-timing;
      width: 0;
      bottom: mixins.vw(-3px, 1024);
      height: mixins.vw(2px, 1024);

      @include mixins.mq("desktop") {
        bottom: mixins.vw(-8px, 1440);
        height: mixins.vw(3px, 1440);
      }

      @include mixins.mq("full-hd") {
        bottom: mixins.vw(-8px, 1920);
        height: mixins.vw(3px, 1920);
      }
    }

    &::before {
      content: "";
      display: block;
      left: 0;
      position: absolute;
      background-size: 100% 100%;
      width: mixins.vw(6px, 1024);
      height: mixins.vw(17px, 1024);

      @include mixins.mq("desktop") {
        width: mixins.vw(13px, 1440);
        height: mixins.vw(24px, 1440);
      }

      @include mixins.mq("full-hd") {
        width: mixins.vw(13px, 1920);
        height: mixins.vw(24px, 1920);
      }
    }

    &:first-child {
      &::before {
        background-image: url("/img/icon-men.svg");
      }
    }

    &:nth-child(2) {
      &::before {
        background-image: url("/img/icon-women.svg");
      }
    }
  }
}
</style>
