export default class {
  getCategories () {
    return require('../data/category.json')
  }

  getGenders () {
    return require('../data/gender.json')
  }

  getDepartments () {
    return require('../data/department.json')
  }
}
export const categoriesWithNonMandatoryPreselection = [0, 1, 2, 5, 8]
