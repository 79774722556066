<template>
  <div class="configuration-overview" :class="{open: isOverviewOpen}">
    <div class="configuration-overview__loading-layer" :class="{isVisible: isGeneratingPdf}">
      <div class="configuration-overview__loading-layer__text">
        PDF wird generiert...
      </div>
    </div>

    <div class="configuration-overview__overview-list">
      <div class="overview-list__header">
        <div class="overview-list__department">
          {{departmentTitle}}
        </div>

        <button @click="closeOverviewClickHandler(false)" class="overview-list__header__back-button">Zurück zur Übersicht</button>
      </div>

      <div class="overview-list__list">
        <div class="overview-list__items__header">
          <div class="overview-list__headline">Artikelübersicht</div>

          <div class="overview-list__gender" :class="{men: genderTitle === 'Herren'}">{{genderTitle}}</div>
        </div>

        <ul
          class="overview-list__items">
          <ConfigurationOverviewCategory
            v-for="category in itemData"
            :key="category.image"
            :image="getCategoryImage(category.image)"
            :title="category.title"
            :id="category.id"
          />
        </ul>
      </div>
    </div>

    <div class="configuration-overview__pdf-download">
      <span class="configuration-overview__pdf-download__headline">
        PDF speichern
      </span>

      <p class="configuration-overview__pdf-download__introtext">
        Geben Sie den Kundennamen ein und speichern Sie Ihre Auswahl lokal auf Ihrem Gerät.
      </p>

      <label for="pdfName">Name der PDF</label>

      <div
        class="input-wrapper"
        :class="{valid: inputIsValid, invalid: interactedWithInput && !inputIsValid}"
      >
        <input
          @click="inputClickHandler"
          type="text"
          name="pdf-name"
          v-model="inputValue"
          required
          placeholder="Kundenname eingeben"
          id="pdfName" />
      </div>

      <button :disabled="!inputIsValid"  class="save-pdf-button" type="button" @click="download" >Speichern</button>
    </div>
  </div>
</template>

<script>
import ConfigurationOverviewCategory from './ConfigurationOverviewCategory'
import PdfService from '../service/PdfService'

export default {
  components: {
    ConfigurationOverviewCategory
  },
  computed: {
    departmentTitle () {
      return this.$store.state.departmentTitle
    },
    genderId () {
      return this.$store.state.genderId
    },
    genderTitle () {
      return this.$store.state.genderTitle
    },
    inputIsValid () {
      return this.inputValue.length >= 1
    },
    isOverviewOpen () {
      return this.$store.state.isOverviewOpen
    },
    itemData () {
      return this.$store.state.categories
    },
    isGeneratingPdf () {
      return this.$store.state.isGeneratingPdf
    }
  },
  data: function () {
    return {
      inputValue: '',
      interactedWithInput: false
    }
  },
  methods: {
    inputClickHandler () {
      this.interactedWithInput = true
    },
    closeOverviewClickHandler: function (targetState) {
      this.$store.commit('setOverviewOpen', targetState)
    },
    download () {
      this.$store.state.isGeneratingPdf = true
      const pdfService = new PdfService(this.$store)
      pdfService.generate(this.inputValue)
    },
    deleteProduct (productId) {
      this.$store.commit('removeClothingItem', productId)
    },
    // Get categoryimage for current gender or default to male icon if unset
    getCategoryImage (categoryImage) {
      return (categoryImage[this.genderId]) ? categoryImage[this.genderId] : categoryImage[1]
    }
  },
  name: 'TheConfigurationOverview'
}
</script>

<style lang="scss" scoped>
@use '../scss/colors';
@use '../scss/animation';
@use '../scss/mixins';

.configuration-overview {
  display: flex;
  height: 100vh;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 4;

  &.open {
    pointer-events: auto;

    .configuration-overview {
      &__overview-list,
      &__pdf-download {
        transform: translateY(0);
      }
    }
  }

  &__loading-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    font-weight: 700;
    background-color: rgba(colors.$midnight, 0.5);
    z-index: 10;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;

    &.isVisible {
      opacity: 1;
      pointer-events: auto;
    }

    &__text {
      color: white;
      font-size: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__overview-list,
  &__pdf-download {
    transition: transform animation.$long-transition-timing;
    width: 50vw;
  }

  &__overview-list {
    background-color: colors.$wild-sand;
    transform: translateY(-100vh);
    overflow-y: auto;

    .overview-list {
      &__department {
        color: colors.$midnight;
        line-height: 140%;
        font-weight: 600;
        font-size: mixins.vw(17px, 1024);

        @include mixins.mq("desktop") {
          font-size: mixins.vw(17px, 1440);
        }

        @include mixins.mq("full-hd") {
          font-size: mixins.vw(17px, 1920);
        }
      }

      &__header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: mixins.vw(31px 70px 28px 70px, 1024);
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 6;

        @include mixins.mq("desktop") {
          padding: mixins.vw(31px 70px 28px 70px, 1440);
        }

        @include mixins.mq("full-hd") {
          padding: mixins.vw(31px 70px 28px 70px, 1920);
        }

        &__back-button {
          background-color: transparent;
          cursor: pointer;
          position: relative;
          transition: background-color animation.$basic-transition-timing, color animation.$basic-transition-timing;
          border: mixins.vw(1px, 1024) solid colors.$midnight;
          font-size: mixins.vw(14px, 1024);
          padding: mixins.vw(12px 15px 12px 33px, 1024);

          @include mixins.mq("desktop") {
            border: mixins.vw(1px, 1440) solid colors.$midnight;
            font-size: mixins.vw(14px, 1440);
            padding: mixins.vw(12px 15px 12px 33px, 1440);
          }

          @include mixins.mq("full-hd") {
            border: mixins.vw(1px, 1920) solid colors.$midnight;
            font-size: mixins.vw(14px, 1920);
            padding: mixins.vw(12px 15px 12px 33px, 1920);
          }

          &:hover,
          &:focus {
            background-color: colors.$midnight;
            color: white;

            &::after,
            &::before {
              background-color: white;
            }
          }

          &::after,
          &::before {
            background-color: colors.$midnight;
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            transform-origin: left;
            transition: background-color animation.$basic-transition-timing;
            left: mixins.vw(15px, 1024);
            height: mixins.vw(2px, 1024);
            width: mixins.vw(8px, 1024);

            @include mixins.mq("desktop") {
              left: mixins.vw(15px, 1440);
              height: mixins.vw(2px, 1440);
              width: mixins.vw(8px, 1440);
            }

            @include mixins.mq("full-hd") {
              left: mixins.vw(15px, 1920);
              height: mixins.vw(2px, 1920);
              width: mixins.vw(8px, 1920);
            }
          }

          &::after {
            transform: rotate(45deg) translateY(-50%);
          }

          &::before {
            transform: rotate(-45deg) translateY(-50%);
          }
        }
      }

      &__headline {
        color: colors.$midnight;
        line-height: 140%;
        position: relative;
        font-size: mixins.vw(24px, 1024);

        @include mixins.mq("desktop") {
          font-size: mixins.vw(32px, 1440);
        }

        @include mixins.mq("full-hd") {
          font-size: mixins.vw(32px, 1920);
        }

        &::after {
          background-image: url("/img/icon-article-overview.svg");
          background-size: 100% 100%;
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          height: mixins.vw(30px, 1024);
          right: mixins.vw(-34px, 1024);
          width: mixins.vw(19px, 1024);

          @include mixins.mq("desktop") {
            height: mixins.vw(45px, 1440);
            right: mixins.vw(-54px, 1440);
            width: mixins.vw(34px, 1440);
          }

          @include mixins.mq("full-hd") {
            height: mixins.vw(45px, 1920);
            right: mixins.vw(-54px, 1920);
            width: mixins.vw(34px, 1920);
          }
        }
      }

      &__list {
        background-color: colors.$wild-sand;
        padding: mixins.vw(49px 70px, 1024);

        @include mixins.mq("desktop") {
          padding: mixins.vw(49px 70px, 1440);
        }

        @include mixins.mq("full-hd") {
          padding: mixins.vw(49px 70px, 1920);
        }
      }

      &__items {
        list-style-type: none;
        margin-bottom: mixins.vw(48px, 1024);

        @include mixins.mq("desktop") {
          margin-bottom: mixins.vw(48px, 1440);
        }

        @include mixins.mq("full-hd") {
          margin-bottom: mixins.vw(48px, 1920);
        }

        &__header {
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin-bottom: mixins.vw(27px, 1024);

          @include mixins.mq("desktop") {
            margin-bottom: mixins.vw(27px, 1440);
          }

          @include mixins.mq("full-hd") {
            margin-bottom: mixins.vw(27px, 1920);
          }
        }
      }

      &__gender {
        background-color: transparent;
        border: none;
        color: colors.$midnight;
        font-weight: 600;
        line-height: 140%;
        position: relative;
        font-size: mixins.vw(17px, 1024);
        padding-left: mixins.vw(20px, 1024);

        @include mixins.mq("desktop") {
          font-size: mixins.vw(17px, 1440);
          padding-left: mixins.vw(20px, 1440);
        }

        @include mixins.mq("full-hd") {
          font-size: mixins.vw(17px, 1920);
          padding-left: mixins.vw(20px, 1920);
        }

        &.men {
          &:before {
            background-image: url("/img/icon-men.svg");
          }
        }

        &:not(.men) {
          &:before {
            background-image: url("/img/icon-women.svg");
          }
        }

        &.active {
          font-weight: 600;
        }

        &::after {
          background-color: colors.$midnight;
          content: "";
          display: block;
          left: 0;
          position: absolute;
          width: 100%;
          height: mixins.vw(3px, 1024);
          bottom: mixins.vw(-8px, 1024);

          @include mixins.mq("desktop") {
            height: mixins.vw(3px, 1440);
            bottom: mixins.vw(-8px, 1440);
          }

          @include mixins.mq("full-hd") {
            height: mixins.vw(3px, 1920);
            bottom: mixins.vw(-8px, 1920);
          }
        }

        &::before {
          content: "";
          display: block;
          left: 0;
          position: absolute;
          background-size: 100% 100%;
          height: mixins.vw(24px, 1024);
          width: mixins.vw(13px, 1024);

          @include mixins.mq("desktop") {
            height: mixins.vw(24px, 1440);
            width: mixins.vw(13px, 1440);
          }

          @include mixins.mq("full-hd") {
            height: mixins.vw(24px, 1920);
            width: mixins.vw(13px, 1920);
          }
        }
      }
    }
  }

  &__pdf-download {
    background-color: colors.$east-bay;
    overflow: hidden;
    position: relative;
    transform: translateY(100vh);
    padding: mixins.vw(149px 115px, 1024);

    @include mixins.mq("desktop") {
      padding: mixins.vw(149px 115px, 1440);
    }

    @include mixins.mq("full-hd") {
      padding: mixins.vw(149px 115px, 1920);
    }

    &::before {
      animation: animated-gradient 15s ease infinite;
      background: linear-gradient(90deg, #3f5273, #536481);
      background-size: 400% 400%;
      content: "";
      display: block;
      position: absolute;
      transform: rotate(45deg);
      bottom: mixins.vw(-170px, 1024);
      left: mixins.vw(500px, 1024);
      height: mixins.vw(976px, 1024);
      width: mixins.vw(976px, 1024);

      @include mixins.mq("desktop") {
        bottom: mixins.vw(-170px, 1440);
        left: mixins.vw(500px, 1440);
        height: mixins.vw(976px, 1440);
        width: mixins.vw(976px, 1440);
      }

      @include mixins.mq("full-hd") {
        bottom: mixins.vw(-170px, 1920);
        left: mixins.vw(500px, 1920);
        height: mixins.vw(976px, 1920);
        width: mixins.vw(976px, 1920);
      }
    }

    &__introtext {
      color: white;
      line-height: 111.5%;
      font-size: mixins.vw(14px, 1024);
      max-width: mixins.vw(330px, 1024);
      margin-bottom: mixins.vw(16px, 1024);

      @include mixins.mq("desktop") {
        font-size: mixins.vw(14px, 1440);
        max-width: mixins.vw(330px, 1440);
        margin-bottom: mixins.vw(16px, 1440);
      }

      @include mixins.mq("full-hd") {
        font-size: mixins.vw(14px, 1920);
        max-width: mixins.vw(330px, 1920);
        margin-bottom: mixins.vw(16px, 1920);
      }
    }

    label {
      color: transparent;
      font-size: 0;
      opacity: 0;
      transform: translateX(-9999px);
    }

    .input-wrapper {
      position: relative;
      margin-bottom: mixins.vw(30px, 1024);
      width: mixins.vw(330px, 1024);

      @include mixins.mq("desktop") {
        margin-bottom: mixins.vw(30px, 1440);
        width: mixins.vw(330px, 1440);
      }

      @include mixins.mq("full-hd") {
        margin-bottom: mixins.vw(30px, 1920);
        width: mixins.vw(330px, 1920);
      }

      &.valid {
        &::after {
          transform: translateY(-50%) scale(1);
        }
      }

      &.invalid {
        &::before {
          opacity: 1;
        }
      }

      &::after,
      &::before {
        display: block;
        pointer-events: none;
        position: absolute;
        top: 50%;
        right: mixins.vw(15px, 1024);

        @include mixins.mq("desktop") {
          right: mixins.vw(15px, 1440);
        }

        @include mixins.mq("full-hd") {
          right: mixins.vw(15px, 1920);
        }
      }

      &::after {
        background-image: url("/img/Icon-checkmark.svg");
        background-size: 100% 100%;
        content: '';
        transform: translateY(-50%) scale(0);
        transition: transform animation.$basic-transition-timing;
        height: mixins.vw(16px, 1024);
        width: mixins.vw(16px, 1024);

        @include mixins.mq("desktop") {
          height: mixins.vw(16px, 1440);
          width: mixins.vw(16px, 1440);
        }

        @include mixins.mq("full-hd") {
          height: mixins.vw(16px, 1920);
          width: mixins.vw(16px, 1920);
        }
      }

      &::before {
        color: red;
        content: '- Bitte ausfüllen -';
        font-size: inherit;
        opacity: 0;
        transform: translateY(-50%);
        transition: opacity animation.$basic-transition-timing;
      }
    }

    input {
      background-color: white;
      border: none;
      box-shadow: none;
      outline: none;
      transition: background-color animation.$basic-transition-timing;
      width: 100%;
      padding: mixins.vw(15px 16px, 1024);

      @include mixins.mq("desktop") {
        padding: mixins.vw(15px 16px, 1440);
      }

      @include mixins.mq("full-hd") {
        padding: mixins.vw(15px 16px, 1920);
      }

      &:hover,
      &:focus {
        background-color: colors.$mercury;
      }

      &:active {
        background-color: lighten(colors.$mercury, 10%);
      }

      &::placeholder {
        color: colors.$midnight;
        line-height: 140%;
        font-size: mixins.vw(14px, 1024);

        @include mixins.mq("desktop") {
          font-size: mixins.vw(14px, 1440);
        }

        @include mixins.mq("full-hd") {
          font-size: mixins.vw(14px, 1920);
        }
      }
    }

    &__headline {
      color: white;
      display: inline-block;
      line-height: 140%;
      position: relative;
      font-size: mixins.vw(32px, 1024);
      margin-bottom: mixins.vw(24px, 1024);

      @include mixins.mq("desktop") {
        font-size: mixins.vw(32px, 1440);
        margin-bottom: mixins.vw(24px, 1440);
      }

      @include mixins.mq("full-hd") {
        font-size: mixins.vw(32px, 1920);
        margin-bottom: mixins.vw(24px, 1920);
      }

      &::after {
        background-image: url("/img/icon-pdf.svg");
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: mixins.vw(45px, 1024);
        width: mixins.vw(36px, 1024);
        right: mixins.vw(-56px, 1024);

        @include mixins.mq("desktop") {
          height: mixins.vw(45px, 1440);
          width: mixins.vw(36px, 1440);
          right: mixins.vw(-56px, 1440);
        }

        @include mixins.mq("full-hd") {
          height: mixins.vw(45px, 1920);
          width: mixins.vw(36px, 1920);
          right: mixins.vw(-56px, 1920);
        }
      }
    }

    .save-pdf-button {
      background-color: colors.$midnight;
      border: none;
      box-shadow: none;
      color: white;
      cursor: pointer;
      line-height: 111.5%;
      outline: none;
      transition: background-color animation.$basic-transition-timing, color animation.$basic-transition-timing, box-shadow animation.$basic-transition-timing;
      padding: mixins.vw(14px 51px, 1024);
      font-size: mixins.vw(14px, 1024);

      @include mixins.mq("desktop") {
        padding: mixins.vw(14px 51px, 1440);
        font-size: mixins.vw(14px, 1440);
      }

      @include mixins.mq("full-hd") {
        padding: mixins.vw(14px 51px, 1920);
        font-size: mixins.vw(14px, 1920);
      }

      &:hover {
        background-color: colors.$candlelight;
        color: colors.$midnight;
        box-shadow: mixins.vw(0 0 10px, 1024) rgba(colors.$midnight, 0.5);

        @include mixins.mq("desktop") {
          box-shadow: mixins.vw(0 0 10px, 1440) rgba(colors.$midnight, 0.5);
        }

        @include mixins.mq("full-hd") {
          box-shadow: mixins.vw(0 0 10px, 1920) rgba(colors.$midnight, 0.5);
        }
      }

      &:active {
        background-color: lighten(colors.$candlelight, 10%);
      }
    }
  }
}
</style>
