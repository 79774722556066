<template>
  <div
    class="tooltip" :class="icontype">
    {{ message }}
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    message: String,
    icontype: String
  }
}
</script>

<style scoped lang="scss">
@use '../scss/colors';
@use '../scss/mixins';

.tooltip {
  background-color: white;
  color: colors.$midnight;
  line-height: 115%;
  position: relative;
  white-space: nowrap;
  padding: mixins.vw(17px 42px 17px 16px, 1024);
  border-radius: mixins.vw(8px, 1024);
  font-size: mixins.vw(12px, 1024);

  @include mixins.mq("desktop") {
    padding: mixins.vw(17px 42px 17px 16px, 1440);
    border-radius: mixins.vw(8px, 1440);
    font-size: mixins.vw(12px, 1440);
  }

  @include mixins.mq("full-hd") {
    padding: mixins.vw(17px 42px 17px 16px, 1920);
    border-radius: mixins.vw(8px, 1920);
    font-size: mixins.vw(12px, 1920);
  }

  &::before {
    background-color: white;
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(50%, -50%) rotate(45deg);
    border-radius: mixins.vw(2px, 1024);
    width: mixins.vw(14px, 1024);
    height: mixins.vw(14px, 1024);

    @include mixins.mq("desktop") {
      border-radius: mixins.vw(2px, 1440);
      width: mixins.vw(14px, 1440);
      height: mixins.vw(14px, 1440);
    }

    @include mixins.mq("full-hd") {
      border-radius: mixins.vw(2px, 1920);
      width: mixins.vw(14px, 1920);
      height: mixins.vw(14px, 1920);
    }
  }

  &::after {
    background-size: 100% 100%;
    content: "";
    display: block;
    height: 14px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: mixins.vw(16px, 1024);
    right: mixins.vw(16px, 1024);

    @include mixins.mq("desktop") {
      width: mixins.vw(16px, 1440);
      right: mixins.vw(16px, 1440);
    }

    @include mixins.mq("full-hd") {
      width: mixins.vw(16px, 1920);
      right: mixins.vw(16px, 1920);
    }
  }

  &.success {
    &::after {
      background-image: url("/img/Icon-checkmark.svg");
    }
  }

  &.info {
    &::after {
      background-image: url("/img/icon-info.svg");
    }
  }
}
</style>
