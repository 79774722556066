<template>
  <div class="configuration-builder">
    <div class="configuration-builder__header__wrapper">
      <div class="configuration-builder__header">
        <TheDepartmentSelection />

        <TheGenderSelection />
      </div>

      <div class="configuration-builder__subdepartment">
        <TheSubdepartmentSelection />
      </div>
    </div>

    <TheClothingCategorySelection />

    <TheClothingItemSelection />
  </div>
</template>

<script>
import TheClothingCategorySelection from './TheClothingCategorySelection'
import TheClothingItemSelection from './TheClothingItemSelection'
import TheDepartmentSelection from './TheDepartmentSelection'
import TheGenderSelection from './TheGenderSelection'
import TheSubdepartmentSelection from './TheSubdepartmentSelection'

export default {
  components: {
    TheClothingCategorySelection,
    TheClothingItemSelection,
    TheDepartmentSelection,
    TheGenderSelection,
    TheSubdepartmentSelection
  },
  name: 'TheConfigurationBuilder'
}
</script>

<style scoped lang="scss">
@use "../scss/colors";
@use "../scss/mixins";

.configuration-builder {
  max-width: 100%;
  overflow: auto;
  max-height: 100vh;
  background-color: colors.$mercury;

  &__subdepartment {
    align-items: center;
    background-color: colors.$wild-sand;
    display: flex;
    justify-content: space-between;
    padding: mixins.vw(12px 25px, 1024);

    @include mixins.mq("desktop") {
      padding: mixins.vw(12px 56px, 1440);
    }

    @include mixins.mq("full-hd") {
      padding: mixins.vw(12px 56px, 1920);
    }
  }

  &__header {
    display: flex;
    box-shadow: mixins.vw(0 5px 15px, 1024) rgba(0, 0, 0, 0.055);
    padding: mixins.vw(11px 25px 18px 25px, 1024);

    @include mixins.mq("desktop") {
      box-shadow: mixins.vw(0 5px 15px, 1440) rgba(0, 0, 0, 0.055);
      padding: mixins.vw(21px 56px 28px 56px, 1440);
    }

    @include mixins.mq("full-hd") {
      box-shadow: mixins.vw(0 5px 15px, 1920) rgba(0, 0, 0, 0.055);
      padding: mixins.vw(21px 56px 28px 56px, 1920);
    }

    &__wrapper {
      position: sticky;
      top: 0;
      z-index: 3;
      background-color: white;
    }
  }
}
</style>
