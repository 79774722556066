<template>
  <div class="subdepartment-selection">
    <button
      :class="{open: isOpen, show: isDepartmentSet}"
      @click="toggleDropDownState"
      class="subdepartment-selection__current">
      {{currentDepartment}}
    </button>

    <ul class="subdepartment-selection__list subdepartment-selection__inactive">
      <li
        v-for="subdepartment in subDepartmentsforDepartment"
        :class="{active: checkActiveState(subdepartment.id)}"
        @click="setSubdepartmentValue(subdepartment)"
        :key="subdepartment.id">
        {{subdepartment.title}}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      isOpen: false,
      subdepartments: []
    }
  },
  name: 'TheSubdepartmentSelection',
  computed: {
    isDepartmentSet () {
      return this.$store.state.departmentId
    },
    subDepartmentsforDepartment () {
      return this.$store.state.subdepartments
    },
    currentDepartment () {
      return this.$store.state.subdepartmentTitle
    }
  },
  methods: {
    checkActiveState (subdepartmentId) {
      return subdepartmentId === this.$store.state.subdepartmentId
    },
    setSubdepartmentValue (thisSubdepartment) {
      const state = this.$store.state
      if (state.genderId && this.checkActiveState(thisSubdepartment.id) === false) {
        this.$store.commit('setResetNoticeOpen', true)
        this.$store.commit('writeResetCache',
          {
            departmentId: state.departmentId,
            departmentTitle: state.departmentTitle,
            genderId: state.genderId,
            genderTitle: state.genderTitle,
            subdepartmentId: thisSubdepartment.id,
            subdepartmentTitle: thisSubdepartment.title
          }
        )
      } else {
        this.$store.commit('setSubdepartment', { subdepartmentId: thisSubdepartment.id, subdepartmentTitle: thisSubdepartment.title })

        for (const item of this.subdepartments) {
          item.isActive = false
        }

        thisSubdepartment.isActive = true
      }
      this.toggleDropDownState()
    },

    toggleDropDownState () {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style scoped lang="scss">
@use '../scss/animation';
@use '../scss/colors';
@use '../scss/mixins';

.subdepartment-selection {
  position: relative;

  &__current,
  &__list li {
    background-color: white;
    cursor: pointer;
    display: block;
    transition: background-color animation.$basic-transition-timing;
    padding: mixins.vw(10px, 1024);
    min-width: mixins.vw(200px, 1024);
    font-size: mixins.vw(10px, 1024);

    @include mixins.mq("desktop") {
      padding: mixins.vw(16px, 1440);
      min-width: mixins.vw(340px, 1440);
      font-size: mixins.vw(16px, 1440);
    }

    @include mixins.mq("full-hd") {
      padding: mixins.vw(16px, 1920);
      min-width: mixins.vw(340px, 1920);
      font-size: mixins.vw(16px, 1920);
    }

    &:hover,
    &.active {
      background-color: colors.$mercury;
    }

    &.active {
      font-weight: 600;
    }
  }

  &__current {
    border: none;
    font-weight: 600;
    position: relative;
    text-align: left;

    &::after {
      background-image: url("/img/icon-dropdown-arrow.svg");
      background-size: 100% 100%;
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: mixins.vw(5px, 1024);
      right: mixins.vw(10px, 1024);
      width: mixins.vw(11px, 1024);

      @include mixins.mq("desktop") {
        height: mixins.vw(8px, 1440);
        right: mixins.vw(24px, 1440);
        width: mixins.vw(14px, 1440);
      }

      @include mixins.mq("full-hd") {
        height: mixins.vw(8px, 1920);
        right: mixins.vw(24px, 1920);
        width: mixins.vw(14px, 1920);
      }
    }

    &.open {
      &::after {
        transform: rotate(180deg);
      }

      + .subdepartment-selection__list {
        box-shadow: mixins.vw(0 5px 25px, 1024) rgba(0, 0, 0, 0.15);
        max-height: mixins.vw(220px, 1024);

        @include mixins.mq("desktop") {
          box-shadow: mixins.vw(0 5px 25px, 1440) rgba(0, 0, 0, 0.15);
          max-height: mixins.vw(220px, 1440);
        }

        @include mixins.mq("full-hd") {
          box-shadow: mixins.vw(0 5px 25px, 1920) rgba(0, 0, 0, 0.15);
          max-height: mixins.vw(220px, 1920);
        }
      }
    }
  }

  &__list {
    box-shadow: none;
    left: 0;
    list-style-type: none;
    max-height: 0;
    overflow: auto;
    position: absolute;
    transition: box-shadow animation.$basic-transition-timing, max-height animation.$basic-transition-timing;
    z-index: 2;
    top: mixins.vw(44px, 1024);

    @include mixins.mq("desktop") {
      top: mixins.vw(44px, 1440);
    }

    @include mixins.mq("full-hd") {
      top: mixins.vw(44px, 1920);
    }
  }
}
</style>
