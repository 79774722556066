<template>
  <button v-show="isGenderSet" @click="overviewButtonClickHandler(true)" class="checkout-trigger">
    <img class="checkout-trigger__icon" src="/img/icon-checkout.svg" alt="">

    <Tooltip
      v-show="addedProduct"
      message="Produkt wurde hinzugefügt"
      icontype="success" />

    <Tooltip
      v-show="deletedProduct"
      message="Produkt wurde entfernt"
      icontype="success" />

    <div
      class="checkout-trigger__amount"
      :class="{show: checkoutItemAmount >= 1}">
      <span class="checkout-trigger__amount__text">
        {{checkoutItemAmount}}
      </span>
    </div>

    <span class="checkout-trigger__text">Artikel-<br />übersicht</span>
  </button>
</template>

<script>
import Tooltip from './Tooltip'

export default {
  components: {
    Tooltip
  },
  computed: {
    isGenderSet: function () {
      return this.$store.state.genderId
    },
    checkoutItemAmount: function () {
      return this.$store.state.itemAmount
    },
    addedProduct: function () {
      return this.$store.state.addedProduct
    },
    deletedProduct: function () {
      return this.$store.state.deletedProduct
    }
  },
  methods: {
    overviewButtonClickHandler: function (targetState) {
      this.$store.commit('setOverviewOpen', targetState)
    }
  },
  name: 'TheCheckout'
}
</script>

<style scoped lang="scss">
@use '../scss/animation';
@use '../scss/colors';
@use '../scss/mixins';

.checkout-trigger {
  background-color: transparent;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: absolute;
  z-index: 4;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity animation.$basic-transition-timing;
  right: mixins.vw(20px, 1024);

  @include mixins.mq("desktop") {
    right: mixins.vw(30px, 1440);
  }

  @include mixins.mq("full-hd") {
    right: mixins.vw(30px, 1920);
  }

  &:focus,
  &:hover {
    opacity: 1;
  }

  &::after {
    background-color: colors.$candlelight;
    content: "";
    position: absolute;
    height: mixins.vw(3px, 1024);
    right: mixins.vw(-30px, 1024);
    top: mixins.vw(30px, 1024);
    width: mixins.vw(24px, 1024);

    @include mixins.mq("desktop") {
      height: mixins.vw(3px, 1440);
      right: mixins.vw(-30px, 1440);
      top: mixins.vw(30px, 1440);
      width: mixins.vw(24px, 1440);
    }

    @include mixins.mq("full-hd") {
      height: mixins.vw(3px, 1920);
      right: mixins.vw(-30px, 1920);
      top: mixins.vw(30px, 1920);
      width: mixins.vw(24px, 1920);
    }
  }

  .tooltip {
    left: 0;
    position: absolute;
    top: mixins.vw(10px, 1024);
    transform: translateX(calc(-100% - #{mixins.vw(34px, 1024)}));

    @include mixins.mq("desktop") {
      top: mixins.vw(10px, 1440);
      transform: translateX(calc(-100% - #{mixins.vw(34px, 1440)}));
    }

    @include mixins.mq("full-hd") {
      top: mixins.vw(10px, 1920);
      transform: translateX(calc(-100% - #{mixins.vw(34px, 1920)}));
    }
  }

  &__amount {
    background-color: rgba(colors.$candlelight, 0.9);
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: scale(0);
    transition: opacity animation.$basic-transition-timing, transform animation.$basic-transition-timing;
    border-radius: mixins.vw(28px, 1024);
    right: 0;
    height: mixins.vw(15px, 1024);
    width: mixins.vw(15px, 1024);

    @include mixins.mq("desktop") {
      border-radius: mixins.vw(28px, 1440);
      right: mixins.vw(-5px, 1440);
      height: mixins.vw(21px, 1440);
      width: mixins.vw(21px, 1440);
    }

    @include mixins.mq("full-hd") {
      border-radius: mixins.vw(28px, 1920);
      right: mixins.vw(-5px, 1920);
      height: mixins.vw(21px, 1920);
      width: mixins.vw(21px, 1920);
    }

    &.show {
      opacity: 1;
      transform: scale(1);
    }

    &__text {
      font-weight: 600;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: mixins.vw(10px, 1024);

      @include mixins.mq("desktop") {
        font-size: mixins.vw(14px, 1440);
      }

      @include mixins.mq("full-hd") {
        font-size: mixins.vw(14px, 1920);
      }
    }
  }

  &__icon {
    display: block;
    transform: scale(1);
    transition: transform animation.$basic-transition-timing;
    margin: 0 auto mixins.vw(9px, 1024) auto;
    width: mixins.vw(30px, 1024);
    height: auto;

    @include mixins.mq("desktop") {
      margin: 0 auto mixins.vw(9px, 1440) auto;
      width: auto;
    }

    @include mixins.mq("full-hd") {
      margin: 0 auto mixins.vw(9px, 1920) auto;
    }
  }

  &__text {
    color: white;
    display: block;
    font-weight: 400;
    line-height: 115%;
    text-align: center;
    font-size: mixins.vw(12px, 1024);

    @include mixins.mq("desktop") {
      font-size: mixins.vw(14px, 1440);
    }

    @include mixins.mq("full-hd") {
      font-size: mixins.vw(14px, 1920);
    }
  }

  .tooltip {
    animation: fade-and-stop 2s forwards;
    opacity: 0;
    left: 0;
    transform: translateY(mixins.vw(50px, 1024)) translateX(calc(-100% - #{mixins.vw(14px, 1024)}));

    @include mixins.mq("desktop") {
      transform: translateY(mixins.vw(50px, 1440)) translateX(calc(-100% - #{mixins.vw(14px, 1440)}));
    }

    @include mixins.mq("full-hd") {
      transform: translateY(mixins.vw(50px, 1920)) translateX(calc(-100% - #{mixins.vw(14px, 1920)}));
    }
  }
}

@keyframes fade-and-stop {
  0% {
    opacity: 0;
    transform: translateY(50px) translateX(calc(-100% - 14px));
  }

  15% {
    opacity: 1;
    transform: translateY(0) translateX(calc(-100% - 14px));
  }

  85% {
    opacity: 1;
    transform: translateY(0) translateX(calc(-100% - 14px));
  }

  100% {
    opacity: 0;
    transform: translateY(-50px) translateX(calc(-100% - 14px));
  }
}
</style>
