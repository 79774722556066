<template>
  <p class="headline-with-arrow">{{text}}</p>
</template>

<script>
export default {
  name: 'HeadlineWithArrow',
  props: {
    text: String
  }
}
</script>

<style scoped lang="scss">
@use "../scss/colors";
@use "../scss/mixins";

.headline-with-arrow {
  color: colors.$midnight;
  display: inline-block;
  font-weight: 600;
  position: relative;
  font-size: mixins.vw(12px, 1024);
  margin-bottom: mixins.vw(16px, 1024);

  @include mixins.mq("desktop") {
    font-size: mixins.vw(14px, 1440);
    margin-bottom: mixins.vw(16px, 1440);
  }

  @include mixins.mq("full-hd") {
    font-size: mixins.vw(14px, 1920);
    margin-bottom: mixins.vw(16px, 1920);
  }

  &::after {
    background-image: url("/img/icon-arrow-down.svg");
    background-size: 100% 100%;
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: mixins.vw(18px, 1024);
    right: mixins.vw(-17px, 1024);
    width: mixins.vw(8px, 1024);

    @include mixins.mq("desktop") {
      height: mixins.vw(18px, 1440);
      right: mixins.vw(-17px, 1440);
      width: mixins.vw(8px, 1440);
    }

    @include mixins.mq("full-hd") {
      height: mixins.vw(18px, 1920);
      right: mixins.vw(-17px, 1920);
      width: mixins.vw(8px, 1920);
    }
  }
}
</style>
