import JsPDF from 'jspdf'
import html2canvas from 'html2canvas'

export default class {
  constructor (store) {
    this.store = store
    this.detailKeyX = 20
    this.detailValueX = 130
    this.detailGenderY = 130
    this.detailDepartmentY = 155
    this.detailSubDepartmentY = 180
    this.detailTableNameX = 225
    this.detailTableCategoryX = 485
    this.detailTableHeaderY = 220
    this.detailTableProductX = 185
    this.detailTableProductRowY = 230
    this.visualImageX = 20
    this.tableLineX1 = 20
    this.tableLineY = 235
    this.tableLineX2 = 500
    this.fontSizes = {
      TitleFontSize: 20,
      detailKeyFontSize: 13,
      detailValueFontSize: 10
    }
    this.genderTitle = store.state.genderTitle
    this.departmentTitle = store.state.departmentTitle
    this.subdepartmentTitle = store.state.subdepartmentTitle
    this.productInfo = store.state.selectedClothingItems
    this.storeCategories = store.state.categories
  }

  generate (customerId) {
    var doc = new JsPDF('p', 'pt')

    this.createHeaderFieldName(doc)
    this.createHeaderValues(doc)
    this.createHeaderProductsTable(doc)
    this.createProductsTableAndSavePdf(doc, customerId)
  }

  createHeaderFieldName (doc) {
    doc.setFontSize(this.fontSizes.detailKeyFontSize).setFont(undefined, 'bold')
    doc.setTextColor('#001B3C')
    doc.text('Geschlecht     ', this.detailKeyX, this.detailGenderY)
    doc.text('Sparte  ', this.detailKeyX, this.detailDepartmentY)
    doc.text('Einsatzbereich  ', this.detailKeyX, this.detailSubDepartmentY)
  }

  createHeaderValues (doc) {
    doc.setTextColor('#000000')
    doc.setFontSize(this.fontSizes.detailValueFontSize).setFont(undefined, 'normal')
    doc.text(this.genderTitle, this.detailValueX, this.detailGenderY)
    doc.text(this.departmentTitle, this.detailValueX, this.detailDepartmentY)
    doc.text(this.subdepartmentTitle, this.detailValueX, this.detailSubDepartmentY)
  }

  createHeaderProductsTable (doc) {
    doc.setTextColor('#001B3C')
    doc.setFontSize(this.fontSizes.detailKeyFontSize).setFont(undefined, 'bold')
    doc.text('Name', this.detailTableNameX, this.detailTableHeaderY)
    doc.text('Kategorie', this.detailTableCategoryX, this.detailTableHeaderY)
  }

  createProductsTableAndSavePdf (doc, customerId) {
    const that = this
    const categories = this.productInfo
    const pageWidth = doc.internal.pageSize.getWidth()
    const pageHeight = doc.internal.pageSize.getHeight()

    doc.setFont(undefined, 'normal')
    doc.setTextColor('#000000')

    const generatedCanvas = document.querySelector('#generatedCanvas')
    if (generatedCanvas !== null) {
      generatedCanvas.remove()
    }

    const addProductImagesAndVisualToPdf = async () => {
      // Function for single image loading
      const checkImage = path =>
        new Promise(resolve => {
          const img = new Image()
          img.onload = () => resolve({ path, status: 'ok' })
          img.onerror = () => resolve({ path, status: 'error' })

          img.src = path
        })

      // function for array destructuring and checking if all image promises are resolved
      const loadImagesFromArray = (...paths) => Promise.all(paths.map(checkImage))

      // Await canvas generation and add visual image to document
      const visualImage = new Image()
      visualImage.src = await appendCanvasAndReturnBase64Image()
      doc.addImage(visualImage, 'png', that.visualImageX, that.detailTableHeaderY, 130, 375)

      // Load layout images
      const layoutImagePaths = [
        '/pdf/top-left.png',
        '/pdf/top-right.png',
        '/pdf/bottom-left.png',
        '/pdf/bottom-right.png'
      ]

      loadImagesFromArray(...layoutImagePaths)
        .then(() => {
          // Add images for PDF-Layout to pdf
          const topLeftImage = new Image()
          const topRightImage = new Image()
          const bottomLeftImage = new Image()
          const bottomRightImage = new Image()
          topLeftImage.src = layoutImagePaths[0]
          topRightImage.src = layoutImagePaths[1]
          bottomLeftImage.src = layoutImagePaths[2]
          bottomRightImage.src = layoutImagePaths[3]
          doc.addImage(topLeftImage, 'png', 20, 50)
          doc.addImage(topRightImage, 'png', pageWidth - 150, 50)
          doc.addImage(bottomLeftImage, 'png', 20, pageHeight - 50)
          doc.addImage(bottomRightImage, 'png', pageWidth - 75, pageHeight - 50)

          // Push all thumbnail paths to Array
          const productImagePaths = []
          for (const category in categories) {
            for (const product of categories[category]) {
              if (product.hideOnCheckout !== true) {
                productImagePaths.push(product.thumbnail)
              }
            }
          }

          // load all images from destructured path array
          loadImagesFromArray(...productImagePaths)
            .then(() => {
              // After all images are loaded to cache, draw the table and add product images to pdf
              let i = 1
              let productRow = that.detailTableProductRowY
              const img = new Image()
              doc.setFontSize(this.fontSizes.detailValueFontSize)
              for (const category in categories) {
                for (const product of categories[category]) {
                  if (product.hideOnCheckout !== true) {
                    // Draw colored table rows
                    if (i % 2 !== 0) {
                      doc.setFillColor('#F6F6F6')
                      doc.rect(that.detailTableProductX - 10, productRow, pageWidth - 200, 50, 'F')
                    }

                    // Add product texts for row
                    const categoryName = this.storeCategories[product.category - 1].title
                    doc.text(product.title, that.detailTableNameX, productRow + 25)
                    doc.text(categoryName, that.detailTableCategoryX, productRow + 25)

                    // calculate new Image sizes and center the image
                    img.src = product.thumbnail
                    let newImageWidth
                    let newImageHeight
                    if (product.thumbnailHeight >= product.thumbnailWidth) {
                      const scaleFactor = 29 / product.thumbnailHeight
                      newImageWidth = product.thumbnailWidth * scaleFactor
                      newImageHeight = 29
                    } else {
                      const scaleFactor = 29 / product.thumbnailWidth
                      newImageWidth = 29
                      newImageHeight = product.thumbnailHeight * scaleFactor
                    }
                    const productY = productRow + (25 - newImageHeight / 2)
                    const productX = that.detailTableProductX + (15 - newImageWidth / 2)
                    console.log(productY)
                    console.log(productX)
                    doc.addImage(img, 'png', productX, productY, newImageWidth, newImageHeight)

                    productRow += 50
                    i++
                  }
                }
              }

              doc.save(customerId + '-' + this.currentDate() + '.pdf')
              this.store.state.isGeneratingPdf = false
            })
            .catch(err => {
              console.log('Fehler beim Laden der Produktbilder: ' + err)
              return false
            })
        })
        .catch((err) => {
          console.log('Fehler beim Laden der Layoutbilder:' + err)
          return false
        })
    }

    addProductImagesAndVisualToPdf()
  }

  currentDate () {
    return new Date().toJSON().slice(0, 10).replace(/-/g, '/')
  }
}

function appendCanvasAndReturnBase64Image () {
  return html2canvas(document.querySelector('#canvas'))
    .then(canvas => {
      const newGeneratedCanvas = document.body.appendChild(canvas)
      newGeneratedCanvas.id = 'generatedCanvas'
      newGeneratedCanvas.classList.add('hidden')
      return newGeneratedCanvas.toDataURL()
    })
    .catch((err) => {
      console.log('Konnte Visual nicht generieren: ' + err)
      return false
    })
}
